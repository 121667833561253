import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import headerimg from '../../assets/headerimage.jpg';

const AnimatedHeader = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollThreshold = 500;
  const progress = Math.min(scrollPosition / scrollThreshold, 1);

  const scale = 1 - (progress * 0.2); // Scale from 1 to 0.8
  const translateY = progress * 50;
  const borderRadius = progress * 20;

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: "easeOut" }
    }
  };

  return (
    <>
      {/* Spacer div to maintain scroll space during animation */}
      <div style={{ height: `${scrollThreshold}px` }} />

      <motion.div
        style={{
          position: scrollPosition >= scrollThreshold ? 'absolute' : 'fixed',
          top: scrollPosition >= scrollThreshold ? scrollThreshold : 0,
          left: 0,
          right: 0,
          height: '100vh',
          width: '100%',
          zIndex: 0
        }}
      >
        <motion.div
          style={{
            height: '100%',
            width: '100%',
            backgroundImage: `url(${headerimg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            transform: `scale(${scale}) translateY(${translateY}px)`,
            borderRadius: `${borderRadius}px`
          }}
        >
          <div
            className="absolute inset-0 bg-black/20 flex flex-col items-center justify-center text-white px-4"
            style={{ borderRadius: `${borderRadius}px` }}
          >
            <motion.h1
              initial="hidden"
              animate="visible"
              variants={textVariants}
              className="text-6xl uppercase seasons lg:text-8xl mt-[60px] font-medium mb-2 sm:mb-4 tracking-tight text-center"
            >
              Shape My House
            </motion.h1>
            <motion.p
              initial="hidden"
              animate="visible"
              variants={textVariants}
              className="text-2xl arvo md:text-3xl lg:text-4xl opacity-90 text-center"
            >
              Shaping Your Dreams
            </motion.p>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default AnimatedHeader;