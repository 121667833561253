import React from 'react';
import { Building2, Paintbrush, HomeIcon, Users, Hammer, Compass, ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';

const ServicesGrid = () => {
  const sers = [
    {
      title: 'Interior Design',
      icon: Paintbrush,
      description: 'Creating stunning spaces that blend aesthetics with functionality.',
      color: 'bg-opacity-20 bg-brown-600',
      iconColor: 'text-brown-800',
      link: '/service/interior-design'
    },
    {
      title: 'Architecture Design',
      icon: HomeIcon,
      description: 'Innovative architectural solutions that transform ideas into reality.',
      color: 'bg-opacity-20 bg-brown-600',
      iconColor: 'text-brown-800',
      link: '/service/architecture'
    },
    {
      title: 'Vastu Consultancy',
      icon: Compass,
      description: 'Traditional Vastu principles applied to modern living spaces.',
      color: 'bg-opacity-20 bg-brown-600',
      iconColor: 'text-brown-800',
      link: '/service/vastu-consultancy'
    }
  ];

  return (
    <div>
      <div className="mt-8 mb-8 mx-auto">
        <p
          className="text-4xl px-4 text-[#7f5635] text-center md:text-5xl seasons mb-12"
        >
          All Services Under One Roof
        </p>
      </div>
      <div className="w-full max-w-6xl mx-auto px-4 mb-24">
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {sers.map((service, index) => (
            <a key={index} href={service.link}>
              <motion.div
                className={`p-8 cursor-pointer relative transition-transform duration-300 rounded-xl backdrop-filter backdrop-blur-lg ${service.color}`}
                style={{ boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', border: '1px solid rgba(255, 255, 255, 0.2)' }}
                whileHover="hover"
                initial="initial"
                animate="initial"
              >
                <div className="flex flex-col items-center text-center">
                  <motion.div
                    className={`${service.iconColor} mb-6`}
                    variants={{
                      initial: { scale: 1 },
                      hover: { scale: 1.1 }
                    }}
                  >
                    {React.createElement(service.icon, { size: 48, strokeWidth: 1.5 })}
                  </motion.div>
                  <h3 className="text-2xl font-semibold mb-4 text-brown-900 seasons text-[#7f5635]">{service.title}</h3>
                  <p className="text-neutral-700 arvo text-sm">{service.description}</p>
                  <motion.div
                    className='flex justify-center items-center rounded-full absolute bottom-3 right-3 h-8 w-8 bg-[#9f8067]/40'
                    variants={{
                      initial: {
                        scale: 1,
                        x: 0
                      },
                      hover: {
                        scale: [1, 1.2, 1],
                        x: [0, -10, 0],
                        transition: {
                          repeat: Infinity,
                          repeatType: "reverse",
                          duration: 1.2
                        }
                      }
                    }}
                  >
                    <ArrowRight />
                  </motion.div>
                </div>
              </motion.div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesGrid;
