import React, { useState } from 'react'
import { motion } from 'framer-motion';
import Lightbox from '../Lightbox';
import pr1 from "../../assets/pr1.jpeg"
import n2 from "./images/sector105/n2.jpeg"
import n6 from "./images/sector105/n6.jpeg"
import n1 from "./images/sector105/n1.jpeg"
import n9 from "./images/sector105/n9.jpeg"
import { Helmet } from 'react-helmet';
import { Car, BedDouble, SquareParking, Utensils, MapPin,StepForward } from 'lucide-react';



function Project1() {

  const [openLightbox, setOpenLightbox] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  // Add image data
  const projectImages = [
    {
      src: n2,
      alt: "Exterior View",
      title: "Modern Exterior Design"
    },
    {
      src: n6,
      alt: "Living Area",
      title: "Spacious Living Room"
    },
    {
      src: n1,
      alt: "Kitchen",
      title: "Modular Kitchen"
    },
    {
      src: n9,
      alt: "Bedroom",
      title: "Master Bedroom"
    }
  ];

  function lightboxopen(index) {
    setCurrentImageIndex(index)
    setOpenLightbox(true)
  }


  return (
    <div className='min-h-screen w-full'>
      <Helmet>
          <title>New construction with the duplex concept in Noida Sector 105</title>
          <meta
            name="description"
            content="Enjoy your life in a trendy home in 105 of Noida which was designed and allocated by the Noida Authority and has excellent transportation connections to Delhi."
          />
        </Helmet>
      {/* Hero Banner */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[80vh] overflow-hidden"
      >
        <div className="absolute inset-0 bg-black/40 z-10" />
        <img
          src={pr1}
          alt="Luxury Property"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-5xl md:text-[100px] seasons mt-[100px]"
            >
              Sector 105, Noida
            </motion.h1>
            <motion.p
              initial={{ y: 40, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className='text-3xl seasons2'
            >
              Modern Villa with Duplex Concept
            </motion.p>
          </div>
        </div>
      </motion.div>


      <div className='max-w-6xl mx-auto px-4 py-16 sm:px-6 lg:px-8'>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-8"
        >
          <h2 className='text-3xl md:text-4xl seasons font-semibold text-[#7f5635]/90 mb-8'>Project Overview</h2>
        </motion.div>


        {/* Project Description */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            <span className='text-2xl seasons text-[#7f5635]/80'>Luxurious Modern Villa with Park <span className='arvo'>&</span> Garden Views</span>
            <br /><br/>
            Presenting one of our latest architectural masterpieces, this stunning villa is a perfect blend of modern elegance and timeless design. Situated on a 200-square-meter plot, this three-story residence offers a thoughtfully designed layout with ground-level parking, a duplex unit spanning the first and second floors, and an independent unit on the third floor.
          </p>

          {/* Add Property Specifications section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="rounded-xl mb-2 overflow-hidden"
          >
            <div className="border-b border-[#7f5635]/10">
              <h3 className="text-2xl seasons text-[#7f5635] px-8 py-6 font-bold">Property Specifications</h3>
            </div>

            <div className="p-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                <div className="flex items-center space-x-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-[#7f5635]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                  </svg>
                  <div>
                    <p className="text-sm text-gray-500 arvo">Plot Area</p>
                    <p className="text-lg font-semibold text-[#7f5635]">200 sq. Mt.</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <Car size={40} className='text-[#7f5635]' />
                  <div>
                    <p className="text-sm text-gray-500 arvo">Road Width</p>
                    <p className="text-lg font-semibold text-[#7f5635]">24 meters</p>
                  </div>
                </div>
              </div>

              <div className="rounded-lg">
                <h4 className="text-xl seasons text-[#7f5635] mb-4 font-bold">Property Features</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {[
                    { icon: <BedDouble size={20} className='text-[#7f5635]' />, text: "Three-Story Residence" },
                    { icon: <Car size={20} className='text-[#7f5635]' />, text: "4 Private Parking Spaces" },
                    { icon: <SquareParking size={20} className='text-[#7f5635]' />, text: "5 Additional Parking Spaces" },
                    { icon: <Utensils size={20} className='text-[#7f5635]' />, text: "Modern Kitchen" },
                    { icon: <MapPin size={20} className='text-[#7f5635]' />, text: "15 mins from Delhi" },
                    { icon: <MapPin size={20} className='text-[#7f5635]' />, text: "25 mins from Greater Noida" },
                    { icon: <StepForward size={20} className='text-[#7f5635] -rotate-90' />, text: "5 Pax Elevator" },
                  ].map((facility, index) => (
                    <div key={index} className="flex items-center gap-4 space-x-2">
                      {facility.icon}
                      <span className="arvo text-gray-600">{facility.text}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>

          <p className="text-gray-500 arvo leading-relaxed text-justify">
            The villa's exquisite elevation showcases a seamless fusion of modern and classic aesthetics, accentuated by the sophisticated use of granite stone throughout the façade. This corner villa enjoys a prime location, with a beautiful park to the right and a lush green garden in the front, providing serene and picturesque views.
            <br />
            With direct access to a 24-meter-wide road, this home offers exceptional convenience, featuring four private parking spaces within the premises and additional five parking spaces outside.
            <br />
            Designed for comfort and luxury, this villa is the perfect choice for those seeking a prestigious lifestyle in a prime location.
            <br/><br/>
            Loctaion:
            <br/>
            This villa is situated in Noida's prestigious Sector 105, one of the most premium neighborhoods in the city. Conveniently located, it is just 15 minutes from Delhi and 25 minutes from Greater Noida, offering seamless connectivity and an ideal balance of urban convenience and tranquility.
          </p>
        </motion.div>

        {openLightbox && (
          <Lightbox
            images={projectImages}
            currentIndex={currentImageIndex}
            setCurrentIndex={setCurrentImageIndex}  // Fix: Pass setCurrentIndex
            setOpenLightbox={setOpenLightbox}
          />
        )}

        {/* Project Images Gallery */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>Project Gallery</h2>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
            {projectImages.map((image, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="relative group overflow-hidden rounded-lg cursor-pointer"
                onClick={() => lightboxopen(index)}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="w-full h-[300px] cursor-pointer object-cover transition-transform duration-300 group-hover:scale-105"
                />
                {/* <div className="absolute inset-0 bg-black/60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                  <h3 className="text-white text-xl seasons">{image.title}</h3>
                </div> */}
              </motion.div>
            ))}
          </div>
        </motion.div>

      </div>
    </div>
  )
}

export default Project1