import React from 'react'
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import blog from "./blog-asset/SMH001.jpg"

function Blog2() {
  return (
    <div className='min-h-screen w-full'>
      <Helmet>
        <title>The Importance of the Home-Orientation | Shape My House</title>
        <meta
          name="description"
          content="Shape my house is the best builder in Delhi NCR and construction company in Delhi NCR. Our team is by your side to ensure you have a hassle-free experience"
        />
        <meta name="robots" content="noindex, nofollow"/>
      </Helmet>

      {/* Hero Banner */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[80vh] overflow-hidden"
        style={{backgroundImage:`url(${blog})`}}
      >
        <div className="absolute inset-0 bg-black/60 z-10" />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-5xl md:text-[100px] seasons mt-[100px]"
            >
              Home Orientation 
            </motion.h1>
            <motion.p
              initial={{ y: 40, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className='text-3xl seasons2'
            >
              Know why home orientation is necessary
            </motion.p>
          </div>
        </div>
      </motion.div>

      <div className='max-w-6xl mx-auto px-4 py-16 sm:px-6 lg:px-8'>
      <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <img 
            src={blog}
            alt="Property Valuation Process" 
            className="w-full h-auto rounded-lg mb-16"
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl md:text-4xl seasons font-semibold text-[#7f5635]/90 mb-8'>Know why home orientation is necessary</h2>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            The position or orientation of your home with respect to the sun's path and any prevailing winds is referred to as house orientation. When your house is oriented properly, you may block sunlight in the summer and receive just the correct quantity of solar radiation to warm it up in the winter. This can significantly reduce the amount of time you spend using heating and cooling systems, which will lower your electricity costs.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>Principles of Good Orientation</h2>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">The Indian construction industry's growth</p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">Critical insight into the impact of industry trends and issues</p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">Analysis of the mega-project pipeline</p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">The report's key highlights are -</p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">The most crucial elements to take into account for optimal orientation are</p>
          <ol className="list-decimal list-inside text-gray-500 leading-relaxed arvo mb-6 text-justify pl-4">
            <li>The climate in the area</li>
            <li>Your site or building's real north and sun angles</li>
            <li>selecting the best construction style for your climate zone</li>
            <li>The results of global warming</li>
          </ol>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            Ideally, you should choose the best construction company for home with good orientation for the climate in your region or just google, the best house renovation near me and build or renovate to maximize the site's potential for passive heating and passive cooling.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">construction company in Delhi NCR</p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>Benefits of Home Orientation</h2>
          <ol className="list-decimal list-inside text-gray-500 leading-relaxed arvo mb-6 text-justify pl-4">
            <li>You are shielded from the sun's harmful rays by the open area to the west.</li>
            <li>In warmer climates, cross ventilation improves indoor comfort; in cold climates, it decreases it.</li>
            <li>The building must be oriented east-west rather than north-south in order to reduce glare from the sun's rising and setting rays. This is necessary for visual comfort.</li>
          </ol>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            If you're clueless and not getting structure properly just check house builders near me, who will help you with the best.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>Orientation For Location</h2>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            The location of your home is a crucial consideration when constructing the ideal ventilation system since it enables you to compare the wind flow produced by efficient and inefficient ventilation systems. There's a plus point if you're living in Delhi, you can get a quotation with the best builder in Delhi NCR easily at your convenience.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>Rainfall</h2>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            The sunshades will help to protect your inside area because rainfall might be at its heaviest during cyclones. In order to control the interior temperature at night, heat is preserved by sunshades. Thus, home orientation enables you to take full use of natural light and ventilation in all climatic situations and these all ways can be implemented by any experienced construction company in Delhi simply.
          </p>
        </motion.div>
      </div>
    </div>
  )
}

export default Blog2