import React from 'react'
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import blog from "./blog-asset/SMH0011.jpg"

function Blog7() {
  return (
    <div className='min-h-screen w-full'>
      <Helmet>
        <title>The Primary Dissimilarities between Modern and Ancient Homes</title>
        <meta
          name="description"
          content="Here we have explained the variance between Modern and Ancient Homes. We are the best construction company in Gurgaon and one of the top builders in Gurgaon."
        />
        <meta name="robots" content="noindex, nofollow"/>
      </Helmet>

      {/* Hero Banner */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[80vh] overflow-hidden"
        style={{backgroundImage:`url(${blog})`}}
      >
        <div className="absolute inset-0 bg-black/60 z-10" />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-5xl md:text-[100px] seasons mt-[100px]"
            >
              Modern vs Ancient Homes
            </motion.h1>
            <motion.p
              initial={{ y: 40, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className='text-3xl seasons2'
            >
              Evolution of Home Architecture and Vastu Shastra
            </motion.p>
          </div>
        </div>
      </motion.div>

      <div className='max-w-6xl mx-auto px-4 py-16 sm:px-6 lg:px-8'>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <img 
            src={blog}
            alt="Modern vs Ancient Architecture" 
            className="w-full h-auto rounded-lg mb-16"
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl md:text-4xl seasons font-semibold text-[#7f5635]/90 mb-8'>Modern vs Ancient Homes: The Evolution of Architecture</h2>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            Modernization is the greatest change in today's world and it has grasped the world in its arms like an octopus. Everything is renovating or modifying according to the latest trends such as technology, fashion, utilities – and homes are no exception. Traditional or regular homes which embarked some sense and warmth of history, Colonial, Vintage and Victorian styles are slowly heading back, making way for modern architecture.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            Nowadays designing of any home having their own chronology as there are a sum total of detailed planning, design, and execution and resonate individual personalities of their inhabitants sensibly.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            So, let's discuss the major differences between modern houses and ancient homes and how they are incorporated with the techniques like Vastu Shastra? Here we are trying to find answers to these questions today!
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>Key Differences Between Modern and Ancient Homes</h2>
          
          <h3 className='text-2xl seasons font-semibold text-[#7f5635]/90 mb-4'>1) Simplicity over complexity</h3>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • Let's talk about the ancient architecture of the era 19th and 20th centuries where there is a base which is set on some elegant and voluminous designs on the other hand modern houses have it's core on maximizing space in an economical & at affordable price which can be done by some of the best construction company in Gurgaon. This idea is reflected in every last aspect of how it is carried out.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • Ancient architecture focused on the features with embellishments and over-elaboration while. Modern architecture, on the other hand, form a structure showing off its sharp features. For this there is a saying of Da Vinci, "Simplicity is the ultimate sophistication!"
          </p>

          <h3 className='text-2xl seasons font-semibold text-[#7f5635]/90 mb-4'>2) Changes in execution</h3>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • In the era of an ancient architecture, local artisans and top notched artisans beautifully carved drawing or pictures on the walls with their hands. While the architecture of modern dwellings depends on talent and creativity.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • With the rapid advancement in building technology, many builders in Gurgaon done modern architecture on the basis of proper strategy and principles like effective planning, scheduling, and management.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • As a result, it helps with project optimization, testing the quality of the materials, and design, paving the way for a well-built building form.
          </p>

          <h3 className='text-2xl seasons font-semibold text-[#7f5635]/90 mb-4'>3) Differences in elements</h3>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • From vintage to present time, various notable differences can be seen while comparing modern vs. ancient homes. The importance of storage in modern homes cannot be overstated.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • Modern homes provide greater emphasis on minimalistic designs with maximum profitability than traditional homes, which place more emphasis on ornamental elements and ornate interiors as well as the artwork.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • Nowadays we can see compact kitchen counters, which provides more area for storage. Although floors which are placed mostly in lighter shades so that it makes room for more light that enhances the space, all the while making it appear bigger!
          </p>

          <h3 className='text-2xl seasons font-semibold text-[#7f5635]/90 mb-4'>4) Form and figure</h3>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • Because of urbanization and globalization lack of land is an important issue that the modern era faces. There was space back then for buildings to sprawl out. In contrast to horizontal construction, vertical construction is currently gaining greater momentum. And also some of the construction techniques such as MIVAN, RCC, and even 3D Printing is greatly influencing the forms.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • In today's world top builders in Gurgaon are doing more experiments and is willing to deviate from traditional, customary norms while it comes to design.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • Investment is a core aspect that rules any field, and similar is to architecture. Exponential growth and rapid advancements in modern times have brought more options. Back then, larger investments were made only for monumental buildings. Now scenes are changing, and today, more efforts and money are put on even commercial buildings globally.
          </p>

          <h3 className='text-2xl seasons font-semibold text-[#7f5635]/90 mb-4'>5) Alternatives in materials</h3>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • While ancient architecture had only few options to choose from materials while modern architecture has a goldmine of options! For an example, Terra Cota Bricks which were used at ancient time now have been easily replaced by concrete blocks and plastering by the usage of gypsum boards.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • Not just the materials but the technique of embracing has also undergone a huge transformation. For instance, highly efficient hydraulic compacted rammed earth has an alternative to conventional rammed earth usage in swift.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • Also, in this period, the industry has become very specific and choosy, and professionals and even labor know what they exactly require rather than making do with whatever limited options come laborers in the way.
          </p>

          <h3 className='text-2xl seasons font-semibold text-[#7f5635]/90 mb-4'>6) The system of vastu shastra</h3>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • The spatial geometry, arrangement, orientation, layout, measurement, and scaling – the whole scope of architectural execution is covered in the traditional and believed ancient system of Vastu Shastra.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • The guidelines which were issued for this system were originally followed in the execution of projects in the former age. Now the language, meaning and base of Vastu Shastra have been completely changed and surprisingly it turned out to be a course of study.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            • Nowadays Vastu shastra has been counted as a professional course in colleges and institutions and many people make it their profession also it is religiously put to use in architecture.
          </p>
          
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            We hope you found the article useful!
          </p>
        </motion.div>
      </div>
    </div>
  )
}

export default Blog7