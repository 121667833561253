import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';


const ServicePoint = ({ title, description, index, subPoints = [] }) => {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.6, delay: index * 0.1 }}
      className="flex items-start space-x-4 p-4 rounded-lg transition-colors duration-300"
    >
      
      <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-full bg-[#f7eee7]">
        <span className="text-[#9f8067] font-semibold">{(index + 1).toString().padStart(2, '0')}</span>
      </div>
      <div className="space-y-4">
        <div>
          <h3 className="text-xl font-semobold arvo text-[#7f5635]/80 mb-2">{title}</h3>
          {description && <p className="text-gray-500 arvo">{description}</p>}
        </div>
        {subPoints.length > 0 && (
          <div className="space-y-3 pl-4">
            {subPoints.map((point, idx) => (
              <div key={idx} className="space-y-1">
                <h4 className="font-medium arvo text-[#7f5640]">{point.title} :</h4>
                <p className="text-gray-500 arvo">{point.description}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </motion.div>
  );
};

function VastuConsultancy() {
  const vastuServices = [
    {
      title: "Personalized Vastu Consultation:",
      subPoints: [
        {
          title: "Initial Analysis",
          description: "We start with an in-depth analysis of your existing space, understanding its current layout and energy flow."
        },
        {
          title: "Client Needs",
          description: "We take into account your specific needs, concerns, and goals to tailor our Vastu recommendations."
        }
      ]
    },
    {
      title: "Comprehensive Vastu Analysis:",
      subPoints: [
        {
          title: "Site Inspection",
          description: "Our experts conduct a thorough inspection of the site, considering factors such as orientation, layout, and structural elements."
        },
        {
          title: "Energy Mapping",
          description: "We create a detailed energy map of your space to identify areas that need improvement."
        }
      ]
    },
    {
      title: "Customized Vastu Solutions:",
      subPoints: [
        {
          title: "Structural Modifications",
          description: "Suggesting structural changes to align with Vastu principles, ensuring better energy flow and balance."
        },
        {
          title: "Interior Adjustments",
          description: "Recommending optimal placements for furniture, decor, and other interior elements to enhance positivity and harmony."
        },
        {
          title: "Color and Material Selection",
          description: "Advising on the best colors and materials that align with Vastu principles to promote well-being."
        }
      ]
    }
  ];

  const integrationFeatures = [
    {
      title: "Integration with Architectural and Interior Design",
      subPoints: [
        {
          title: "Seamless Coordination",
          description: "Our Vastu experts work closely with our architects and interior designers to integrate Vastu principles into the design process from the beginning."
        },
        {
          title: "Holistic Approach",
          description: "Ensuring that every aspect of your home or office design aligns with Vastu, creating a cohesive and harmonious environment."
        }
      ]
    }
  ];

  const expertise = [
    {
      title: "Experienced Vastu Experts",
      description: "Our team comprises highly experienced and knowledgeable Vastu consultants who are well-versed in traditional Vastu Shastra."
    },
    {
      title: "Client-Centric Approach",
      description: "We prioritize your well-being and satisfaction, offering personalized solutions tailored to your specific needs."
    },
    {
      title: "Integration with Modern Design",
      description: "We seamlessly blend Vastu principles with modern architectural and interior design, ensuring that your space is both beautiful and harmonious."
    }
  ];

  return (
    <>
      {/* Hero Section */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[80vh] overflow-hidden"
      >
        <Helmet>
          <title>Vastu Consultancy In Delhi NCR | Vastu Consultants In Noida</title>
          <meta
            name="description"
            content="Transform your home with Vastu consultants in Noida, Delhi, and Gurgaon renowned for their expertise. Get effective Vastu solutions that promote prosperity"
          />
        </Helmet>
        <div className="absolute inset-0 bg-black/40 z-10" />
        <img 
          src="https://dm90w54v7z2d.cloudfront.net/servicepages/vastu/Vastu.jpg"
          alt="Vastu Consultancy Services"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1 
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-4xl md:text-6xl seasons mb-4 mt-[100px]"
            >
              Vastu Consultancy Services <br/> <span className='text-3xl'>Delhi NCR</span>
            </motion.h1>
          </div>
        </div>
      </motion.div>

      {/* Main Content */}
      <div className="max-w-5xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        {/* Introduction */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <p className="text-[#7f5635]/80 text-lg arvo leading-relaxed">
            Welcome to Shape My House, where we blend ancient wisdom with modern design to create harmonious living spaces. Our Vastu Consultancy Services are dedicated to ensuring that your home or office not only looks beautiful but also promotes well-being, prosperity, and positive energy flow.
          </p>
        </motion.div>

        {/* Vastu Services */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold seasons text-[#7f5635]/80 mb-3">Our Vastu Consultancy Services:</h2>
          {/* <div className="w-16 h-0.5 bg-[#9f8067] mb-8" /> */}
          <div className="space-y-4">
            {vastuServices.map((service, index) => (
              <ServicePoint 
                key={index}
                title={service.title}
                subPoints={service.subPoints}
                index={index}
              />
            ))}
          </div>
        </div>

        {/* Integration Features */}
        <div className="mb-16">
          <div className="space-y-4">
            {integrationFeatures.map((feature, index) => (
              <ServicePoint 
                key={index}
                title={feature.title}
                subPoints={feature.subPoints}
                index={index + vastuServices.length}
              />
            ))}
          </div>
        </div>

        {/* Expertise */}
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-[#7f5635]/80 seasons mb-3">Our Expertise:</h2>
          {/* <div className="w-16 h-0.5 bg-[#9f8067] mb-8" /> */}
          <div className="space-y-4">
            {expertise.map((item, index) => (
              <ServicePoint 
                key={index}
                title={item.title}
                description={item.description}
                index={index}
              />
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <motion.div 
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center rounded-xl"
        >
          <p className="text-[#7f5635]/80 arvo mb-8">
            Shape My House is your trusted partner for all your needs of Vastu consultancy in Noida. Let us help you create a space that is not only aesthetically pleasing but also filled with positive energy and harmony. Reach out to us today!
          </p>
        </motion.div>
      </div>
    </>
  );
}

export default VastuConsultancy;