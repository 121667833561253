import React from 'react'
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import blog from "./blog-asset/SMH005.jpg"

function Blog5() {
  return (
    <div className='min-h-screen w-full'>
      <Helmet>
        <title>Construction ban in Delhi reimposed as AQI Delhi worsens</title>
        <meta
          name="description"
          content="Delhi pollution and AQI Delhi NCR are the new fuss around Delhi NCR. The pollution level in Delhi has reached the severe stage and Delhi air quality got worse."
        />
        <meta name="robots" content="noindex, nofollow"/>
      </Helmet>

      {/* Hero Banner */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[80vh] overflow-hidden"
        style={{backgroundImage:`url(${blog})`}}
      >
        <div className="absolute inset-0 bg-black/60 z-10" />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-5xl md:text-[100px] seasons mt-[100px]"
            >
              Construction Ban 
            </motion.h1>
            <motion.p
              initial={{ y: 40, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className='text-3xl seasons2'
            >
              Construction ban reimposed in Delhi NCR as air quality worsens
            </motion.p>
          </div>
        </div>
      </motion.div>

      <div className='max-w-6xl mx-auto px-4 py-16 sm:px-6 lg:px-8'>
      <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <img 
            src={blog}
            alt="Property Valuation Process" 
            className="w-full h-auto rounded-lg mb-16"
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>Construction Ban in Delhi NCR</h2>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            The Commission for Air Quality Management (CAQM) again activated Stage III of the Graded Response Action Plan when the average AQI Delhi reached the "severe" stage above 400 on December 4, 2022. As a result, all unnecessary construction work has been prohibited in Delhi NCR. On November 14, 2022, the commission withdrew the necessary restrictions after improved air quality. However, a recent analysis has brought attention to the need to limit unnecessary construction and demolition in Delhi NCR.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>GRAP and its stages</h2>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            The GRAP is a set of measures followed to check the air pollution level in Delhi NCR. Generally, it is divided into four stages based on the region's average AQI.
          </p>
          <div className="mb-8">
            <h3 className="text-xl font-semibold mb-4">Stages of the Graded Response Action Plan</h3>
            <div className="grid grid-cols-4 gap-4 text-gray-500 mb-4">
              <div>
                <p className="font-bold">AQI</p>
                <p>201-300</p>
                <p>301-400</p>
                <p>401-450</p>
                <p>451 and above</p>
              </div>
              <div>
                <p className="font-bold">ZONE</p>
                <p>Poor</p>
                <p>Very poor</p>
                <p>Severe</p>
                <p>Severe plus</p>
              </div>
              <div>
                <p className="font-bold">STAGES OF GRAP</p>
                <p>Stage I</p>
                <p>Stage II</p>
                <p>Stage III</p>
                <p>Stage IV</p>
              </div>
            </div>
          </div>
          
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            The pollution reached the "severe" zone during the last week of October 2022, when the region's AQI was averaging "very poor," and it remained even after November 1, 2022. However, the limits were abolished in the third week of November 2022 since the average AQI Delhi NCR was "poor" and about 100 points below the level required to keep them in place under Stage III of GRAP. Despite the restriction being lifted, CAQM emphasized the need to follow dust control norms.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>Why did Delhi NCR implement Stage III of the GRAP in October 2022?</h2>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            The CAQM adopted Stage III of the GRAP and imposed mandatory limits on construction and demolition activities on October 30, 2022, in response to the alarming rise in pollution levels in Delhi NCR. Since Diwali on October 24, 2022, the area had regularly shown air quality indexes above 300.
          </p>
          
          <h3 className='text-2xl seasons font-semibold text-[#7f5635]/90 mb-4'>Measures taken under Stage III of the GRAP</h3>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            Construction, demolition, and mining operations were severely restricted during Stage III in Delhi, Noida, Ghaziabad, and other NCR cities, among others. Plumbing, electrical, carpentry, and interior decorating projects were among the non-polluting activities that were permitted.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            Excavation, boring, drilling, welding, loading and unloading of building supplies, drainage work, driving on an unpaved road, laying sewer and water lines, building roads, waterproofing, cutting and repairing tiles and stones, and other maintenance work were some of the operations that were prohibited. The Delhi government had assigned over 580 teams with representatives from various departments to work on the matter in order to ensure that the construction restriction wasn't broken.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            However, initiatives related to national security, the military, railroads, and the metro were exempt from the restriction. Additionally, because a delay in the project's completion may result in a penalty of about Rs 10 lakh per day, the prohibition was not relevant to the Noida International Airport, which is currently under development and would be located in Jewar.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            It is crucial to remember that under Stage IV of the GRAP, the restriction would have included restrictions on truck entry into Delhi NCR, only 50 percent workforce to operate out of office in public and private sectors, and temporary closure of educational institutions, to name a few.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            The Noida Authority sprinkled water on various roads as per CAQM's directives, totaling almost 165 km to control the dust flying along these stretches. Additionally, the authority's horticulture division cleaned 25 km of central verges, plants, and roads. Approximately 260.75 tonnes of construction and demolition waste (C&D) were collected from various city sectors and transported to the C&D Processing Plant in Noida Sector-80 for disposal, according to the statement.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            The Yamuna Expressway Industrial Development Authority (YEIDA) began investigating scientific ways of soil stabilization to control construction dust, which is a significant source of Delhi pollution, as it became more and more of an issue. According to Arun Vir Singh, CEO of YEIDA - "As the region suffers from poor AQI every winter, the authority invited organizations to give presentations on the subject. If a suitable methodology is found, the authority will look into making it a part of the construction policy."
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>How can a restriction on construction affect Delhi NCR real estate?</h2>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            Presently, over 5.5 lakh residential units are being constructed in Delhi NCR, including Greater Noida West, Yamuna Expressway, New Gurgaon, Noida Expressway, Dwarka Expressway, Central Noida, Greater Faridabad, Raj Nagar Extension in Ghaziabad, Sohna Road, and Golf Course Extension Road. And there are more than 4 lakh units in these areas combined.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
            According to an industry report, a construction ban in Delhi, due to air pollution could be a serious setback for ongoing projects since residential projects in Delhi NCR typically take 6-8 years to complete. According to experts, prohibiting construction work because of poor Delhi air quality could have negative effects. If construction activity is prohibited for one month, this can delay a project by at least three to four months.
          </p>
        </motion.div>
      </div>
    </div>
  )
}

export default Blog5