import React from 'react';
import Header from '../components/home/Header';
import Constructionbanner from '../components/home/Constructionbanner';
import ServicesGrid from '../components/home/Services';
import Process from '../components/home/WorkProcess';
import Projects from '../components/home/Projects';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <div className="w-full">
      <Header />

      <Helmet>
        <title>Construction Company in Delhi NCR - Builder in Noida</title>
        <meta
          name="description"
          content="Shape My House is your trusted construction company in Delhi NCR and a renowned real estate developer in Noida. Explore our portfolio of innovative projects now"
        />
      </Helmet>

      {/* Add spacing div to account for header height */}
      <div style={{ height: '102vh' }} />

      {/* Content section */}
      <div className='container mx-auto mt-[40px] relative z-20'>
        <div className='flex flex-col items-center'>
          <p className='text-3xl text-center px-8 md:text-6xl font-medium seasons text-[#7f5635]'>
            Welcome to Shape My House
            <p className='text-xl md:text-3xl mt-5'>Your One–Stop Solution for All Construction Needs</p>
          </p>
          <p className='text-sm md:text-xl text-[#9f8067]/90 text-center mt-[14px] arvo md:w-[90%] px-5'>
          At Shape My House, we bring your dream home to life with expert solutions in construction, collaboration, and interior design. if you're looking to build from the ground up , you can get all the services under on roof at shape my house.
          </p>
          {/* <ServicesGrid /> */}
          <Constructionbanner/>
          <div className='mt-8'>
            <Projects />
          </div>
          <Process />
        </div>
      </div>
    </div>
  );
}

export default Home;