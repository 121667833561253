import React from 'react'

function OverlayLink() {
    return (
        <>
            <div className="rounded-md flex flex-col fixed z-40 bottom-0 right-0 gap-5 m-5">
                <a href="tel:+919870168989">
                    <img
                        className="hover:scale-125 transition-all duration-100"
                        width="48"
                        height="48"
                        src="https://www.theshubhamgroup.com/static/media/icon_call.092a33d874f46145879d.png"
                        alt="phone"
                    />
                </a>
                <a href="https://wa.me/919870168989" target="_blank" rel="noreferrer">
                    <img
                        className="hover:scale-125 transition-all duration-100"
                        width="48"
                        height="48"
                        src="https://www.theshubhamgroup.com/static/media/icon_whatsapp.417ad2d4d35da4649b19.png"
                        alt="whatsapp--v1"
                    />
                </a>
            </div>
        </>
    )
}

export default OverlayLink