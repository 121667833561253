import { useLocation } from "react-router-dom";
import Blog2 from "./blogs/Blog2";
import Blog3 from "./blogs/Blog3";
import Blog4 from "./blogs/Blog4";
import Blog5 from "./blogs/Blog5";
import Blog6 from "./blogs/Blog6";
import Blog7 from "./blogs/Blog7";

const BlogDetails = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const blogid = searchParams.get("blogid");

    // Map blogid to corresponding content
    const blogData = {
        "1/know-why-home-orientation-is-necessary": {
            blog: <Blog2 />,
        },
        "8/simple-options-for-home-renovation-in-india--": {
            blog: <Blog3/>
        },
        "4/residential-projects-in-gurgaon---sohna-road-vs-new-gurgaon": {
            blog: <Blog4/>
        },
        "6/construction-ban-reimposed-in-delhi-ncr-as-air-quality-worsens":{
            blog:<Blog5/>
        },
        "10/performance-of-indian-real-estate-through-fy23-and-future-prospects":{
            blog:<Blog6/>
        },
        "12/Modern Homes vs Ancient Homes Top six details you were Overlooked":{
            blog:<Blog7/>
        }
    };

    const blog = blogData[blogid] || { title: "Blog Not Found", content: "The requested blog does not exist." };

    return (
        <>
            {blog.blog}
        </>
    );
};

export default BlogDetails;
