import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import notFoundImage from '../assets/404.png';

function NotFound() {
  return (
    <div className="min-h-screen flex flex-col md:flex-row items-center justify-center bg-[#f7eee7] p-4 md:p-8">
      <div className="text-center w-full md:w-1/2 px-4 md:px-8 mb-8 md:mb-0">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-6xl sm:text-7xl md:text-9xl font-bold text-[#9f8067]">404</h1>
        </motion.div>
    
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="mt-4 md:mt-6"
        >
          <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold text-gray-800 mb-3 md:mb-4">
            Oops! Page Not Found
          </h2>
          <p className="text-sm sm:text-base text-gray-600 mb-6 md:mb-8 px-4">
            The page you're looking for seems to have wandered off. Let's get you back on track!
          </p>
        </motion.div>

        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="flex flex-col sm:flex-row items-center justify-center gap-4"
        >
          <Link 
            to="/"
            className="w-full sm:w-auto px-6 py-2.5 sm:py-3 bg-[#9f8067] text-white text-sm sm:text-base
              rounded-lg hover:bg-[#8b6f58] transition-all duration-300
              transform hover:scale-105"
          >
            Go Home
          </Link>
          
          <button 
            onClick={() => window.history.back()}
            className="w-full sm:w-auto px-6 py-2.5 sm:py-3 border-2 border-[#9f8067] 
              text-[#9f8067] text-sm sm:text-base rounded-lg hover:bg-[#9f8067] 
              hover:text-white transition-all duration-300 transform hover:scale-105"
          >
            Go Back
          </button>
        </motion.div>
      </div>

      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full sm:w-2/3 md:w-1/2 lg:w-1/3 px-8 md:px-0"
      >
        <img 
          src={notFoundImage} 
          className="w-full h-auto object-contain max-h-[300px] md:max-h-[400px]" 
          alt="404 illustration" 
        />
      </motion.div>
    </div>
  );
}

export default NotFound;