import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Lightbulb, Target, ClipboardList, Rocket } from 'lucide-react'

function Process() {
  const [hoveredIndex, setHoveredIndex] = useState(null)

  const processes = [
    {
      number: "01",
      title: "Understanding",
      description: "Client requirements are taken into consideration and plans are initiated.",
      icon: Target,
      gradient: "from-[#9f8067] to-[#7d6552]",
      accent: "bg-[#9f8067]",
      shadow: "shadow-[#9f8067]/20",
      lightGradient: "from-[#9f8067]/10 to-transparent"
    },
    {
      number: "02",
      title: "Idea & Design",
      description: "Our professional team shapes up your ideas into designs.",
      icon: Lightbulb,
      gradient: "from-[#9f8067] to-[#7d6552]",
      accent: "bg-[#9f8067]",
      shadow: "shadow-[#9f8067]/20",
      lightGradient: "from-[#9f8067]/10 to-transparent"
    },
    {
      number: "03",
      title: "Specification",
      description: "Detailing of materials, quantity, and quality of workmanship.",
      icon: ClipboardList,
      gradient: "from-[#9f8067] to-[#7d6552]",
      accent: "bg-[#9f8067]",
      shadow: "shadow-[#9f8067]/20",
      lightGradient: "from-[#9f8067]/10 to-transparent"
    },
    {
      number: "04",
      title: "Execution",
      description: "Performing strategies to get the project across the finish line.",
      icon: Rocket,
      gradient: "from-[#9f8067] to-[#7d6552]",
      accent: "bg-[#9f8067]",
      shadow: "shadow-[#9f8067]/20",
      lightGradient: "from-[#9f8067]/10 to-transparent"
    }
  ]

  return (
    <div className="relative mb-[100px]">
      {/* Subtle Background Pattern */}
      <div className="absolute inset-0 ">
        <div className="absolute inset-0 " />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Heading Section */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center mb-16"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="inline-block relative"
          >
            <span className="text-sm font-light tracking-[0.3em] text-gray-800 block mb-3">
              DISCOVER OUR
            </span>
            <h2 className="text-4xl seasons md:text-7xl font-medium text-[#7f5635]">
              Working Process
            </h2>
            {/* Decorative Underline */}
          </motion.div>
        </motion.div>

        {/* Process Steps Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {processes.map((process, index) => (
            <motion.div
              key={index}
              className="relative group"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              onHoverStart={() => setHoveredIndex(index)}
              onHoverEnd={() => setHoveredIndex(null)}
            >
              <motion.div
                className={`relative h-[280px] p-6 rounded-xl glassfooter shadow-md  overflow-hidden transition-all duration-300`}
                whileHover={{ 
                  y: -5,
                  scale: 1.02,
                  transition: { duration: 0.2 }
                }}
                style={{
                  border: "0"
                }}
              >
                {/* Background Effect */}
                <motion.div
                  className={`absolute inset-0 bg-gradient-to-br ${process.lightGradient} opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
                />

                {/* Number */}
                <motion.div
                  className="absolute -top-6 -left-6 w-24 h-24 flex items-center justify-center"
                  animate={{
                    rotate: hoveredIndex === index ? 360 : 0
                  }}
                  transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
                >
                  <div className={`text-6xl font-bold bg-gradient-to-br ${process.gradient} bg-clip-text text-transparent opacity-20`}>
                    {process.number}
                  </div>
                </motion.div>

                {/* Icon Container */}
                <motion.div
                  className={`relative z-10 p-3 rounded-lg bg-gradient-to-br ${process.gradient} text-white w-fit`}
                  whileHover={{ 
                    scale: 1.1,
                    rotate: [0, -10, 10, -10, 0],
                  }}
                >
                  <process.icon size={20} />
                </motion.div>

                {/* Content */}
                <div className="relative z-10 mt-6">
                  <motion.h3
                    className="text-xl font-semibold text-[#7d6552] mb-3"
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    {process.title}
                  </motion.h3>
                  <motion.p
                    className="text-sm text-[#9f8067] arvo leading-relaxed"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ delay: 0.3 }}
                  >
                    {process.description}
                  </motion.p>
                </div>

                {/* Bottom Accent */}
                <motion.div
                  className={`absolute bottom-0 left-0 right-0 h-1 ${process.accent} opacity-50 transition-opacity duration-300`}
                  initial={{ scaleX: 0 }}
                  whileInView={{ scaleX: 1 }}
                  transition={{ duration: 0.8 }}
                />

                {/* Connecting Lines */}
                {index < processes.length - 1 && (
                  <motion.div
                    className="hidden lg:block absolute top-1/2 -right-6 w-6 h-[2px] bg-[#9f8067]/20"
                    initial={{ scaleX: 0 }}
                    whileInView={{ scaleX: 1 }}
                    transition={{ delay: 0.5 }}
                  />
                )}
              </motion.div>
            </motion.div>
          ))}
        </div>

        {/* Progress Indicators */}
        <div className="flex justify-center gap-2 mt-8">
          {processes.map((_, index) => (
            <motion.div
              key={index}
              className={`w-2 h-2 rounded-full ${
                hoveredIndex === index ? 'bg-[#9f8067]' : 'bg-[#9f8067]/20'
              }`}
              whileHover={{ scale: 1.5 }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Process