import React, { useState, useRef, useEffect } from 'react'
import { Building2, Paintbrush, HomeIcon, Users, Hammer, Compass, ChevronDown, Menu, X } from 'lucide-react'
import Contact from './Contact'
import { motion, AnimatePresence } from 'framer-motion'
import logo from "../assets/SHAPEMYHOUSE.svg"

function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 100);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    // Cleanup function to ensure scroll is re-enabled when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMobileMenuOpen]);

  const services = [
    {
      name: 'Construction',
      icon: < Building2 size={28} className="text-white" strokeWidth={1.5} />,
      description: 'Strategic business solutions and expert guidance',
      href: '/service/construction'
    },
    {
      name: 'Interior Design',
      icon: <Paintbrush size={28} className="text-white" strokeWidth={1.5} />,
      description: 'Custom software and web development',
      href: '/service/interior-design'
    },
    {
      name: 'Architecture Design',
      icon: <HomeIcon size={28} className="text-white" strokeWidth={1.5} />,
      description: 'Creative and intuitive UI/UX design',
      href: '/service/architecture'
    },
    {
      name: 'Collaboration',
      icon: <Users size={28} className="text-white" strokeWidth={1.5} />,
      description: 'Digital marketing and growth strategies',
      href: '/service/collaboration'
    },
    {
      name: 'Renovation',
      icon: <Hammer size={28} className="text-white" strokeWidth={1.5} />,
      description: 'Data-driven insights and reporting',
      href: '/service/renovation'
    },
    {
      name: 'Vastu Consultancy',
      icon: <Compass size={28} className="text-white" strokeWidth={1.5} />,
      description: '24/7 technical support and maintenance',
      href: '/service/vastu-consultancy'
    },
  ];

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <>
      <motion.div
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 50, damping: 20 }}
        className='h-[100px] mx-auto fixed z-40 px-4 md:px-[60px] lg:px-[120px] flex justify-between shadow-lg bg-[#f7eee7] items-center w-full'
      >
        <div className='md:ml-0  ml-2 flex items-center'>
          <a href="/">
            <img
              src={logo}
              className='h-[65px]'
              alt="Logo"
            />
          </a>
        </div>

        <div className='hidden lg:block'>
          <nav>
            <ul className='flex gap-5 justify-between items-center'>
              <li><a href="/" className='cursor-pointer arvo font-medium text-lg hover:text-[#9f8067] transition-colors'>Home</a></li>
              <li><a href="/about_us" className='cursor-pointer arvo font-medium text-lg hover:text-[#9f8067] transition-colors'>About</a></li>
              {/* <li><a href="/service/construction" className='cursor-pointer arvo font-medium text-lg hover:text-[#9f8067] transition-colors'>Construction</a></li> */}
              {/* <li
                className='relative group'
                ref={dropdownRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <button
                  className={`cursor-pointer flex arvo font-medium text-lg items-center gap-1 py-2 hover:text-[#9f8067] transition-colors
                          ${isDropdownOpen ? 'text-[#9f8067]' : ''}`}
                >
                  Services
                  <ChevronDown
                    className={`w-5 h-5 mt-1 transition-transform duration-300 ${isDropdownOpen ? 'rotate-180' : ''}`}
                    size={18}
                  />
                </button>

                <div
                  className={`absolute left-1/2 -translate-x-1/2 mt-0 transition-all duration-300 ease-in-out
                          ${isDropdownOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2 pointer-events-none'}`}
                >
                  <div className="h-2"></div>
                  <div className='w-[600px] bg-[#f7eee7] rounded-2xl shadow-2xl p-6 border border-gray-100'>
                    <div className='grid grid-cols-2 gap-6'>
                      {services.map((service, index) => (
                        <a
                          key={index}
                          href={service.href}
                          className='p-4 hover:text-[#9f8067]'
                        >
                          <div className='flex items-start gap-4'>
                            <div className='p-3 rounded-lg bg-gradient-to-br from-[#9f8067] to-[#7d6552] text-white transition-colors'>
                              {service.icon}
                            </div>
                            <div>
                              <h3 className='font-medium arvo text-lg mb-1 transition-colors'>
                                {service.name}
                              </h3>
                              <p className='text-gray-600 arvo text-sm leading-relaxed'>
                                {service.description}
                              </p>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </li> */}
              <li><a href="/ongoing-projects" className='cursor-pointer arvo font-medium text-lg hover:text-[#9f8067] transition-colors'>Featured Projects</a></li>
              <li><a href="/contact_us.php" className='cursor-pointer arvo font-medium text-lg hover:text-[#9f8067] transition-colors'>Reach Us</a></li>
            </ul>
          </nav>
        </div>

        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="lg:hidden p-2 text-[#7f5635] hover:text-[#9f8067] transition-colors"
        >
          {isMobileMenuOpen ? <X size={28} /> : <Menu size={28} />}
        </button>
      </motion.div>

      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, x: '100%' }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: '100%' }}
            transition={{ type: "tween", duration: 0.3 }}
            className="fixed top-[100px] text-center right-0 w-full h-[calc(100vh-100px)] pb-[100px] bg-[#f7eee7] z-30 lg:hidden overflow-y-auto"
          >
            <nav className="p-6">
              <motion.ul
                className='flex flex-col gap-6'
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <li>
                  <a
                    href="/"
                    onClick={closeMobileMenu}
                    className='block text-xl arvo font-medium hover:text-[#9f8067] transition-colors'
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="/about_us"
                    onClick={closeMobileMenu}
                    className='block text-xl arvo font-medium hover:text-[#9f8067] transition-colors'
                  >
                    About
                  </a>
                </li>

                {/* <motion.div className="w-full text-center">
                  <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className='flex justify-center gap-1 items-center w-full text-xl arvo font-medium hover:text-[#9f8067] transition-colors'
                  >
                    Services
                    <ChevronDown
                      className={`transition-transform mt-1 duration-300 ${isDropdownOpen ? 'rotate-180' : ''}`}
                      size={20}
                    />
                  </button>

                  <AnimatePresence>
                    {isDropdownOpen && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className='bg-[#755635]/10 mt-4 rounded-2xl p-6 border border-gray-100'
                      >
                        <div className='mt-4 space-y-4 pl-4'>
                          {services.map((service, index) => (
                            <a
                              key={index}
                              href={service.href}
                              onClick={closeMobileMenu}
                              className='flex items-center gap-3 hover:text-[#9f8067] transition-colors'
                            >
                              <div className='p-2 rounded-lg bg-gradient-to-br from-[#9f8067] to-[#7d6552]'>
                                {React.cloneElement(service.icon, { size: 20 })}
                              </div>
                              <span className='arvo text-lg'>{service.name}</span>
                            </a>
                          ))}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div> */}

                <li>
                  <a
                    href="/ongoing-projects"
                    onClick={closeMobileMenu}
                    className='block text-xl arvo font-medium hover:text-[#9f8067] transition-colors'
                  >
                    Featured Projects
                  </a>
                </li>
                <li>
                  <a
                    href="/contact_us.php"
                    onClick={closeMobileMenu}
                    className='block text-xl arvo font-medium hover:text-[#9f8067] transition-colors'
                  >
                    Reach Us
                  </a>
                </li>
              </motion.ul>
            </nav>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Contact Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 glassfooter z-50 flex items-center justify-center">
          <div className="relative max-w-md w-full mx-4">
            {/* Contact Component */}
            <Contact isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
          </div>
        </div>
      )}
    </>
  )
}

export default Navbar