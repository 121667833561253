import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';


function Collaboration() {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  const areas = [
    { name: "Delhi", image: "https://media.istockphoto.com/id/1475568218/photo/the-india-gate-is-a-war-memorial-located-at-kartavya-path-new-delhi-india.jpg?s=612x612&w=0&k=20&c=gpsGqunmvZsOLAK5320GA6tUnSf6DpmeVcSEAqUmNKs=" },
    { name: "Gurgaon", image: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0f/b8/e2/a4/photo5jpg.jpg?w=500&h=500&s=1" },
    { name: "Noida", image: "https://t4.ftcdn.net/jpg/05/40/30/71/360_F_540307170_qg8R8Seug9NYJzL7FpbBuW5St0lIyAvv.jpg" },
    { name: "Greater Noida", image: "https://s3.ap-south-1.amazonaws.com/investormart/uploads/images/202405/image_750x_6659b2d923ab0.jpg" },
  ];

  return (
    <>
      <Helmet>
        <title>Property Collaboration in Delhi, Gurgaon, Noida and Faridabad</title>
        <meta
          name="description"
          content="Property Collaboration company in Delhi, Gurgaon, Noida, Ghaziabad, Faridabad, Greater Noida and NCR location by highly experienced professionals."
        />
      </Helmet>


      {/* Main Content */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">

        {/* Introduction */}
        <div className='container mx-auto relative z-20'>
          <div className='flex flex-col items-center'>
            <p className='text-4xl mb-8 text-center px-8 md:text-6xl font-medium seasons text-[#7f5635]'>
              Collaboration
            </p>
          </div>
        </div>
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.6 }}
          className="text-center mb-8"
        >
          <h2 className="text-2xl seasons font-semibold text-[#7f5635]/80 mb-6">What are Collaboration Services?</h2>
          <p className="text-gray-500 max-w-3xl mx-auto text-lg arvo leading-relaxed">
            Do you own a plot but don't want to spend your money to build a new home? Shape My House has the perfect solution for you! We will construct a beautiful, modern home on your plot at our own expense. In exchange, we take ownership of one or more floors in the newly constructed home. We also provide just construction services if you dont want to trade your one floor.
          </p>
        </motion.div>

        {/* What we offer */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-8"
        >
          <h2 className="text-2xl seasons font-semibold text-[#7f5635]/80 text-center mb-6">What we offer in collaboration:</h2>
          <p className="text-gray-500 arvo text-lg leading-relaxed mb-3 text-center">
            From start to end we handle everything. From designing your architectural plan and construction to obtaining approvals and managing neighbors. We ensure timely delivery of your new home, or we pay you a penalty. Before starting any collaboration we will do an agreement to avoid any future confusions. So the both the parties can understand each other part.
          </p>
          <div className="p-6 rounded-lg">
            <p className="text-gray-500 arvo text-lg font-medium text-center">
              You will get a Peace of Mind as Your new home comes with a warranty of up to ten years.
            </p>
          </div>
        </motion.div>

        {/* Why Choose Us */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-8"
        >
          <h2 className="text-2xl seasons text-[#7f5635]/80 font-semibold mb-6 text-center">Why Choose Shape My House?</h2>
          <div className="space-y-4 w-full text-center">
            <span className="text-gray-500 text-center text-lg arvo">No Investment Required: Get a new home without spending your money.</span>
            <br />
            <span className="text-gray-500 text-center text-lg arvo">Hassle-Free Process: We take care of every detail, ensuring a smooth and stress-free experience.</span>

          </div>
        </motion.div>

        {/* Areas We Serve */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-8"
        >
          <h2 className="text-2xl seasons font-semibold text-[#7f5635]/80 mb-6 text-center mt-[40px]">Areas We Serve:</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {areas.map((area, index) => (
              <motion.div
                key={area.name}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="text-xl relative h-52 overflow-hidden rounded-lg arvo text-center text-[#7f5640] shadow-lg"
                style={{
                  backgroundImage:`url(${area.image})`,
                  backgroundPosition:"center",
                  backgroundSize:"cover"
                }}
              >
                <div className=' bottom-3 w-full h-full flex justify-center items-end pb-6 bg-black/20 text-white'>{area.name}</div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </>
  );
}

export default Collaboration;