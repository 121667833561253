import React, { useState } from 'react'
import { motion } from 'framer-motion'
import axios from "axios"

function Contact({ setIsModalOpen, isModalOpen }) {
    const [isOpen, setIsOpen] = useState(true);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: ''
    });


    const formatPhoneNumber = (value) => {
        const phone = value.replace(/\D/g, '').slice(0, 10);
        if (phone.length <= 3) return phone;
        if (phone.length <= 6) return `${phone.slice(0, 3)}-${phone.slice(3)}`;
        return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone') {
            setFormData(prev => ({ ...prev, [name]: formatPhoneNumber(value) }));
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const phoneDigits = formData.phone.replace(/\D/g, '');
        if (phoneDigits.length !== 10) {
            alert('Please enter a valid 10-digit phone number');
            return;
        }
        formData.phone = phoneDigits

        setIsSubmitting(true);
        try {
            await axios.post("https://account.solidperformers.com/leadsapi/data/6d3e75bc39e0266cce1f4967045370d7", formData)
            console.log('Form submitted:', formData);
            setFormData({ name: '', phone: '' });
            setShowSuccess(true);
        } catch (error) {
            alert('Failed to submit form. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen) return null;

    return (
        <>
            <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 }}
                className="bg-[#f7eee7] p-8 rounded-xl shadow-lg border border-[#D4B9A7]"
            >
                {showSuccess ? (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="text-center"
                    >
                        <h3 className="text-2xl seasons font-bold text-[#7d6552] mb-4">Thank You</h3>
                        <p className="text-[#9f8067]">We'll get back to you soon.</p>
                        <motion.button
                            onClick={() => {
                                setShowSuccess(false);
                                setIsOpen(true);
                                if (isModalOpen) setIsModalOpen(false);
                            }}
                            className="mt-6 bg-gradient-to-br from-[#9f8067] to-[#7d6552] text-white 
                                     py-2 px-4 rounded-lg font-medium shadow-md
                                     hover:shadow-lg transition-all duration-300"
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                        >
                            Close
                        </motion.button>
                    </motion.div>
                ) : (
                    <>
                        <div className="flex justify-between mb-8">
                            <h2 className=" text-2xl seasons font-bold text-[#7d6552]">Connect With Us</h2>
                            {isModalOpen && <button onClick={() => setIsModalOpen(false)} className="text-gray-500 hover:text-gray-700 z-50">
                                <span className="text-2xl">✕</span>
                            </button>}
                        </div>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            {['name', 'phone'].map((field, index) => (
                                <motion.div
                                    key={field}
                                    className="relative group"
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.3 + index * 0.1 }}
                                >
                                    <label htmlFor={field} className="block text-sm font-medium text-[#7d6552] mb-2">
                                        {field === 'name' ? 'Full Name' : 'Phone Number'}
                                    </label>
                                    <input
                                        type={field === 'phone' ? 'tel' : 'text'}
                                        id={field}
                                        name={field}
                                        value={formData[field]}
                                        onChange={handleChange}
                                        placeholder={field === 'phone' ? 'Number' : 'Full Name'}
                                        autoComplete={field === 'name' ? 'name' : 'tel'}
                                        className="w-full px-4 py-3 border border-[#D4B9A7] rounded-lg
                                                focus:ring-2 focus:ring-[#9f8067] outline-none bg-transparent focus:border-transparent
                                                transition-all duration-300 ease-in-out
                                                hover:border-[#9f8067]"
                                        required
                                    />
                                    <motion.div
                                        className="absolute bottom-0 left-0 right-0 h-0.5 bg-[#9f8067]"
                                        initial={{ scaleX: 0 }}
                                        // animate={{ scaleX: hoveredField === field ? 1 : 0 }}
                                        transition={{ duration: 0.3 }}
                                    />
                                </motion.div>
                            ))}

                            <motion.button
                                type="submit"
                                disabled={isSubmitting}
                                className="w-full bg-gradient-to-br from-[#9f8067] to-[#7d6552] text-white 
                                         py-3 px-6 rounded-lg font-medium shadow-md
                                         hover:shadow-lg transition-all duration-300
                                         disabled:opacity-50 disabled:cursor-not-allowed"
                                whileHover={{ 
                                    scale: isSubmitting ? 1 : 1.02,
                                    transition: { duration: 0.2 }
                                }}
                                whileTap={{ scale: isSubmitting ? 1 : 0.98 }}
                            >
                                {isSubmitting ? 'Sending...' : 'Send Message'}
                            </motion.button>
                        </form>
                    </>
                )}
            </motion.div>
        </>
    )
}

export default Contact