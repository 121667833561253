import { motion, useInView } from 'framer-motion';
import { ArrowUpRight } from 'lucide-react';
import { useRef } from 'react';

const Constructionbanner = () => {
    const ref = useRef(null);
    const inView = useInView(ref, { once: true });

    return (
        <a href='/about_us' className='p-5 md:p-0'>
            <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 50 }}
                animate={inView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5 }}
                className='container mx-auto flex flex-col md:flex-row gap-4 my-[20px] md:my-[50px] overflow-hidden rounded-xl bg-[#7f5635]/5 group'
            >
                {/* Mobile heading - only shows on mobile */}
                <div className='md:hidden w-full my-6 px-4'>
                    <div className='rounded-xl px-3'>
                        <p className='seasons text-start text-[#7f5635] text-5xl'>
                            Construction <br /> <span className='arvo text-3xl'>&</span> <span className='text-3xl'>Collaboration</span>
                        </p>
                    </div>
                </div>

                {/* Image - full width on mobile */}
                <div className='w-full p-2 md:p-0 h-[40vh] md:w-1/2 md:h-[70vh] flex items-center overflow-hidden order-2 md:order-2' >
                    <img src='https://dm90w54v7z2d.cloudfront.net/servicepages/construction/1.jpg' alt='cons' className='w-full h-full md:rounded-none rounded-lg object-cover group-hover:scale-110 transition-transform duration-700' />
                </div>
                
                {/* Content section */}
                <div className='w-full md:w-1/2 h-auto md:h-[70vh] flex flex-col p-4 md:p-8 justify-between order-3 md:order-1'>
                    {/* Paragraph */}
                    <p className='text-[#9f8067]/90 mt-4 md:mt-8 text-justify text-sm md:text-md arvo'>
                        At Shapemyhouse, we specialize in high-quality construction for residential, commercial, and renovation projects, ensuring each is completed on time and within budget with the highest standards of workmanship.
                    </p>
                    
                    {/* Desktop heading - hidden on mobile */}
                    <div className='hidden md:block my-0'>
                        <div className='bg-[#f7eee7] rounded-xl p-5'>
                            <p className='seasons text-center text-[#7f5635] text-6xl'>
                                Construction <br /> <span className='arvo text-5xl'>&</span> <span className='text-5xl'>Collaboration</span>
                            </p>
                        </div>
                    </div>
                    
                    {/* Button */}
                    <motion.button
                        className="w-fit flex items-center px-4 md:px-8 py-2 md:py-4 text-sm md:text-base text-[#7f5635] rounded-full font-semibold border-[#9f8067]/80 border-2 group-hover:bg-[#9f8067] group-hover:text-white hover:border-[#9f8067] transition-colors duration-300 mt-4 md:mt-0"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        Know More <ArrowUpRight className="ml-1 mt-1" size={16} />
                    </motion.button>
                </div>
            </motion.div>
        </a>
    );
};

export default Constructionbanner;