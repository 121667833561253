import React, { useState } from 'react'
import { motion } from 'framer-motion'
import project1 from "../../assets/pr1.jpeg"
import dlf from "../projects/images/dlf/1.jpg"

function Projects() {
  const [hoveredIndex, setHoveredIndex] = useState(null)

  const projects = [
    {
      id: 1,
      link: '/ongpro2',
      title: `Sector 105, Noida`,
      number: "01",
      image: project1,
      overview: "A Premium Three-Story Villa – Timeless Elegance & Superior Craftsmanship",
      color: "from-[#9f8067]/10 to-transparent"
    },
    {
      id: 2,
      link: '/Property-in-dlf-phase-3-gurgaon',
      title: "DLF Phase 3, Gurugram",
      number: "02",
      image: dlf,
      overview: "A elegant Modern Building with Stilt Parking & Spacious Floors",
      color: "from-[#9f8067]/10 to-transparent"
    },
    {
      id: 3,
      link: '#',
      title: "Jaypee Greens, Geater Noida",
      number: "03",
      image: "https://picsum.photos/seed/13/1200/800",
      overview: "",
      color: "from-[#9f8067]/10 to-transparent"
    },
  ]

  return (
    <div className="min-h-screen text-gray-800 mb-[80px]">
      <div className="max-w-[1600px] mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-4xl md:text-5xl lg:text-6xl seasons font-medium mb-8 tracking-tight text-[#7f5635]"
        >
          Projects
        </motion.h1>

        <div className="space-y-8">
          {projects.map((project, index) => (
            <a href={project.link} key={project.id}>
              <motion.div
                className="relative h-auto md:h-[70vh] mb-12 rounded-3xl overflow-hidden"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, margin: "-100px" }}
                transition={{ duration: 0.8 }}
                onHoverStart={() => setHoveredIndex(index)}
                onHoverEnd={() => setHoveredIndex(null)}
              >
                {/* Background Gradient */}
                <motion.div
                  className={`absolute inset-0 glassfooter shadow-xl border-2 border-[#9f8067]`}
                  initial={{ opacity: 0 }}
                  style={{
                    backgroundColor: "rgba(159, 128, 103, 0.3)"
                  }}
                  whileInView={{ opacity: 0.2 }}
                  whileHover={{ opacity: 0.4 }}
                  transition={{ duration: 0.6 }}
                />

                <div className="relative h-full flex">
                  {/* Project Number */}
                  <motion.div
                    className={`absolute bottom-8 seasons2 ${index % 2 === 0 ? 'right-[60%]' : 'left-[80%]'
                      } mb-2 text-5xl md:text-6xl lg:text-8xl font-bold opacity-20 hidden md:block`}
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 0.2, x: 0 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                  >
                    {project.number}
                  </motion.div>

                  {/* Content Container */}
                  <div className={`grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 w-full p-6 md:p-0 ${index % 2 === 0 ? 'md:pl-16' : 'md:pr-16'
                    }`}>
                    {index % 2 === 0 ? (
                      <>
                        {/* Text Content */}
                        <motion.div
                          className="flex justify-center flex-col"
                          initial={{ opacity: 0, x: -50 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.6, delay: 0.3 }}
                        >
                          <h2 className="text-2xl md:text-3xl lg:text-4xl text-[#7f5635] seasons mb-4 md:mb-8">
                            {project.title}
                          </h2>
                          <p className="text-sm md:text-base max-w-sm md:max-w-md mb-4 arvo opacity-60">
                            {project.overview}
                          </p>
                          <motion.button
                            className="w-fit px-6 md:px-8 mt-4 md:mt-8 py-3 md:py-4 text-[#7f5635] rounded-full font-semibold border-[#9f8067]/80 border-2 hover:bg-[#9f8067] hover:text-white hover:border-[#9f8067] transition-colors duration-300"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                          >
                            View Project
                          </motion.button>
                        </motion.div>

                        {/* Image Container */}
                        <motion.div
                          className="relative h-[300px] md:h-full rounded-2xl md:rounded-[0px_1.5rem_1.5rem_0px] overflow-hidden"
                          initial={{ opacity: 0, x: 50 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.6, delay: 0.4 }}
                        >
                          <motion.img
                            src={project.image}
                            alt={project.title}
                            className="w-full h-full object-cover object-right"
                            initial={{ scale: 1.2 }}
                            // animate={{
                            //   scale: hoveredIndex === index ? 1.1 : 1,
                            //   rotate: hoveredIndex === index ? 2 : 0
                            // }}
                            transition={{ duration: 0.8 }}
                          />
                        </motion.div>
                      </>
                    ) : (
                      <>
                        {/* Mobile-first order adjustment */}
                        <div className="md:hidden">
                          <motion.div
                            className="flex justify-center flex-col"
                            initial={{ opacity: 0, x: -50 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.6, delay: 0.3 }}
                          >
                            <h2 className="text-2xl md:text-3xl lg:text-4xl text-[#7f5635] seasons mb-4 md:mb-8">
                              {project.title}
                            </h2>
                            <p className="text-sm md:text-base max-w-sm md:max-w-md mb-4 arvo opacity-60">
                              {project.overview}
                            </p>
                            <motion.button
                              className="w-fit px-6 md:px-8 mt-4 md:mt-8 py-3 md:py-4 text-[#7f5635] rounded-full font-semibold border-[#9f8067]/80 border-2 hover:bg-[#9f8067] hover:text-white hover:border-[#9f8067] transition-colors duration-300"
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                            >
                              View Project
                            </motion.button>
                          </motion.div>
                        </div>

                        {/* Image Container */}
                        <motion.div
                          className="relative h-[300px] md:h-full rounded-2xl md:rounded-[1.5rem_0px_0px_1.5rem] overflow-hidden"
                          initial={{ opacity: 0, x: -50 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.6, delay: 0.4 }}
                        >
                          <motion.img
                            src={project.image}
                            alt={project.title}
                            className="w-full h-full object-cover"
                            initial={{ scale: 1.2 }}
                            // animate={{
                            //   scale: hoveredIndex === index ? 1.1 : 1,
                            //   rotate: hoveredIndex === index ? 2 : 0
                            // }}
                            transition={{ duration: 0.8 }}
                          />
                        </motion.div>

                        {/* Desktop text content */}
                        <motion.div
                          className="hidden md:flex justify-center flex-col"
                          initial={{ opacity: 0, x: 50 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.6, delay: 0.3 }}
                        >
                          <h2 className="text-2xl md:text-3xl lg:text-4xl text-[#7f5635] seasons mb-4 md:mb-8">
                            {project.title}
                          </h2>
                          <p className="text-sm md:text-base max-w-sm md:max-w-md mb-4 arvo opacity-60">
                            {project.overview}
                          </p>
                          <motion.button
                            className="w-fit px-6 md:px-8 mt-4 md:mt-8 py-3 md:py-4 text-[#7f5635] rounded-full font-semibold border-[#9f8067]/80 border-2 hover:bg-[#9f8067] hover:text-white hover:border-[#9f8067] transition-colors duration-300"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                          >
                            View Project
                          </motion.button>
                        </motion.div>
                      </>
                    )}
                  </div>
                </div>
              </motion.div>
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Projects


