import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';


const ProcessPoint = ({ title, description, index }) => {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.6, delay: index * 0.1 }}
      className="flex items-start space-x-4 p-4 rounded-lg transition-colors duration-300"
    >
      
      <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-full bg-[#f7eee7]">
        <span className="text-[#9f8067] font-semibold">{(index + 1).toString().padStart(2, '0')}</span>
      </div>
      <div>
        <h3 className="text-xl arvo mb-2 text-[#7f5635]">{title}</h3>
        <p className="text-gray-500 arvo">{description}</p>
      </div>
    </motion.div>
  );
};

function InteriorDesign() {
  const designProcess = [
    {
      title: "Initial Consultation and Space Planning:",
      description: "Our design process begins with an in-depth consultation to understand your needs and preferences. We then move on to space planning, ensuring every square foot of your home is utilized effectively."
    },
    {
      title: "Strategy and Concept Development:",
      description: "We devise a tailored strategy to achieve your design goals. Our focus is on creating a seamless blend of aesthetics and functionality, reflecting your unique style."
    }
  ];

  const services = [
    {
      title: "Space Planning:",
      description: "Optimize the layout of your home for maximum efficiency and comfort."
    },
    {
      title: "Furniture Selection:",
      description: "Choose the perfect pieces to complement your space and style."
    },
    {
      title: "Color and Material Selection:",
      description: "Create a cohesive and harmonious color palette with the best materials."
    },
    {
      title: "Lighting Design:",
      description: "Enhance the ambiance and functionality of your home with well-planned lighting solutions."
    }
  ];

  const whyChooseUs = [
    {
      title: "Comprehensive Design Solutions:",
      description: "From luxury home designs to open terrace and wall designs, we offer detailed drawings and plans to bring your vision to life."
    },
    {
      title: "Authenticity and Individuality:",
      description: "We strive to create designs that reflect your individuality and emotions, making your home truly yours."
    },
    {
      title: "Core Values:",
      description: "Our primary focus is to exceed your expectations by adhering to our core values of quality, integrity, and customer satisfaction."
    },
    {
      title: "Commercial and Residential Expertise:",
      description: "We cater to both commercial and residential sectors, ensuring that every project receives the same level of dedication and excellence."
    }
  ];

  return (
    <>
      {/* Hero Section */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[80vh] overflow-hidden"
      >
        <Helmet>
          <title>Interior Designing Company In Noida | Shape My House</title>
          <meta
            name="description"
            content="Get personalized interior design service in Noida, Delhi, and Gurgaon with our interior designing firm. Let our designers create spaces that inspire and delight"
          />
        </Helmet>
        <div className="absolute inset-0 bg-black/10 z-10" />
        <img 
          src="https://dm90w54v7z2d.cloudfront.net/servicepages/interior/4.jpg"
          alt="Interior Design Services"
          className="w-full h-full object-cover object-top"
        />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1 
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-4xl md:text-6xl seasons mt-[100px]"
            >
             Interior Design Services<br/> <span className='text-3xl'>Delhi NCR</span>
            </motion.h1>
          </div>
        </div>
      </motion.div>

      {/* Main Content */}
      <div className="max-w-5xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        {/* Introduction */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <p className="text-[#7f5635]/80 text-lg leading-relaxed arvo">
            At Shape My House, we understand that your home is your sanctuary, and everything about it should be perfect. That's why we are dedicated to transforming your home's interior to enhance its beauty and functionality. With years of authentic experience, we are among the best interior designers in Delhi NCR, committed to bringing your vision to life.
          </p>
        </motion.div>

        {/* Design Process */}
        <div className="mb-16">
          <h2 className="text-2xl seasons text-[#7f5635] font-semibold mb-3">Our Design Process:</h2>
          {/* <div className="w-16 h-0.5 bg-[#9f8067] mb-8" /> */}
          <div className="space-y-4">
            {designProcess.map((process, index) => (
              <ProcessPoint 
                key={index}
                title={process.title}
                description={process.description}
                index={index}
              />
            ))}
          </div>
        </div>

        {/* Services */}
        <div className="mb-16">
          <h2 className="text-2xl  text-[#7f5635] font-semibold seasons mb-3">Our Services:</h2>
          {/* <div className="w-16 h-0.5 bg-[#9f8067] mb-8" /> */}
          <div className="space-y-4">
            {services.map((service, index) => (
              <ProcessPoint 
                key={index}
                title={service.title}
                description={service.description}
                index={index}
              />
            ))}
          </div>
        </div>

        {/* Our Commitment */}
        <motion.div 
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mb-16"
        >
          <h2 className="text-2xl text-[#7f5635] font-semibold seasons mb-6">Our Commitment:</h2>
          <p className="text-gray-600 arvo mb-8">
            Our experienced team of interior designers is dedicated to creating living spaces that are both beautiful and practical. Whether you prefer a modern or traditional look, we ensure the end result is a space you love and feel comfortable in. Our goal is to make the design process enjoyable and stress-free, providing the highest quality service possible.
          </p>
          <p className="text-gray-600 arvo ">
            Shape My House is not just an interior designing company in Noida but also a trusted partner in crafting homes that reflect your unique style and enhance your living experience.
          </p>
        </motion.div>

        {/* Why Choose Us */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold seasons text-[#7f5635]/80 mb-3">Why Choose Shape My House?</h2>
          {/* <div className="w-16 h-0.5 bg-[#9f8067] mb-8" /> */}
          <div className="space-y-4">
            {whyChooseUs.map((reason, index) => (
              <ProcessPoint 
                key={index}
                title={reason.title}
                description={reason.description}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default InteriorDesign;