import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./screens/Home";
import OverlayLink from "./components/OverlayLink";
import About from "./screens/About";
import ReachUs from "./screens/ReachUs";
import Projects from "./screens/Projects";
import NotFound from "./components/NotFound";
import VastuConsultancy from "./components/services/VastuConsultancy";
import Renovation from "./components/services/Renovation";
import Collaboration from "./components/services/Collaboration";
import Construction from "./components/services/Construction";
import ArchitectureDesign from "./components/services/ArchitectureDesign";
import InteriorDesign from "./components/services/InteriorDesign";
import Project1 from "./components/projects/Project1";
import Project2 from "./components/projects/Project2";
import Project3 from "./components/projects/Project3";
import QueryRender from './components/QueryRender';
import Blog1 from './components/blogs/Blog1';

function App() {
  return (
    <Router>
      <Navbar />
      <OverlayLink />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about_us" element={<About />} />
        <Route exact path="/contact_us.php" element={<ReachUs />} />
        <Route path="*" element={<NotFound />} />

        {/* Services */}
        <Route exact path="/service/vastu-consultancy" element={<VastuConsultancy />} />
        <Route exact path="/service/renovation" element={<Renovation />} />
        <Route exact path="/service/collaboration" element={<Collaboration />} />
        <Route exact path="/service/construction" element={<Construction />} />
        <Route exact path="/service/architecture" element={<ArchitectureDesign />} />
        <Route exact path="/service/interior-design" element={<InteriorDesign />} />

        {/* Projects */}
        <Route exact path="/ongoing-projects" element={<Projects />} />
        <Route exact path="/ongpro2" element={<Project1 />} />
        <Route exact path="/Property-in-dlf-phase-3-gurgaon" element={<Project2 />} />

        {/* Dynamic Blog Details Route */}x
        <Route exact path="/blog/how-do-you-figure-out-the-property-valuation" element={<Blog1 />} />
        <Route exact path="/blog-details" element={<QueryRender />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
