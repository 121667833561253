import React from 'react'
import { motion } from 'framer-motion'

function Project3() {
  const projectImages = [
    {
      src: "https://shapemyhouse.com/static/media/ONGPRO3.be75d39a5448a7431669.jpg",
      alt: "Luxury Property",
      title: "Luxurious 4 BHK Apartment in DLF Phase 3"
    },
  ];
  return (
    <div className='min-h-screen w-full'>
      {/* Hero Banner */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[80vh] overflow-hidden"
      >
        <div className="absolute inset-0 bg-black/40 z-10" />
        <img 
          src={projectImages[0].src}
          alt="Luxury Property"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1 
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-5xl md:text-6xl seasons mb-6 mt-[100px]"
            >
              Project 3
            </motion.h1>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default Project3