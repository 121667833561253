import React from 'react'
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import blog from "./blog-asset/SMH003.jpg"

function Blog4() {
  return (
    <div className='min-h-screen w-full'>
      <Helmet>
        <title>Residential Projects In Gurgaon And Property In Sohna Road</title>
        <meta
          name="description"
          content="Gurgaon is one of India's quickly growing real estate markets. Apartments and flats in Gurgaon and property in Sohna Road is top elements of Gurgaon real estate"
        />
        <meta name="robots" content="noindex, nofollow"/>
      </Helmet>

      {/* Hero Banner */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[80vh] overflow-hidden"
        style={{backgroundImage:`url(${blog})`}}
      >
        <div className="absolute inset-0 bg-black/60 z-10" />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-5xl md:text-[100px] seasons mt-[100px]"
            >
              Residential Projects
            </motion.h1>
            <motion.p
              initial={{ y: 40, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className='text-3xl seasons2'
            >
              Residential Projects in Gurgaon - Sohna Road vs New Gurgaon
            </motion.p>
          </div>
        </div>
      </motion.div>

      <div className='max-w-6xl mx-auto px-4 py-16 sm:px-6 lg:px-8'>
      <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <img 
            src={blog}
            alt="Property Valuation Process" 
            className="w-full h-auto rounded-lg mb-16"
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>Residential Projects in Gurgaon - Sohna Road vs New Gurgaon</h2>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
          The real estate market in Gurgaon's Sohna Road and New Gurgaon are both rapidly expanding. Sohna Road is more convenient to the city center, although more affordable housing options and flats in Gurgaon are available. Before making an investment decision, one must consider all the factors.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
          If we talk about, Sohna Road, a roughly 21 km-long section connecting Sohna to Gurgaon, features a number of well-known neighbourhoods located in and nearby, including Sectors 49, 70, and 71. Despite being regarded as Gurgaon's upcoming real estate hub, it is out of many potential homebuyers' price ranges. Sectors 81 to 95 makes up as the emerging region of New Gurgaon, where Sectors 85 and 86 are extremely well-liked. Although it is fueled by reasonably priced real estate, inhabitants face difficulties due to the quality of the civic infrastructure and communication. Both regions are rapidly developing and offer investors a unique combination of opportunities and challenges.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>When thinking about making an investment in either of these places, one must examine all of the following factors:</h2>
          <ol className="list-decimal list-inside text-gray-500 leading-relaxed arvo mb-6 text-justify pl-4">
            <li className="mb-4">
              <span className="font-semibold">Variations in the availability of properties:</span> According to the property analysis, more than half of the properties available for sale on Sohna Road are residential apartments. The remaining half consists primarily of builder floors, farmhouses, and residential lands with sizes ranging from 600 square feet to 9,000 square feet.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Distribution of properties according to the budget:</span> The majority of the New Gurgaon real estate is priced between Rs. 60 lakh and Rs 1 crore. On the other side, Sohna Road contains about 40% of the homes priced between Rs. 1-2 crore. One of the causes of this is that Sohna Road offers farmhouses and residential land parcels, most of which cost Rs 1 crore or more. There is a lack of affordable home options between Rs 25 lakh and Rs 60 lakh exists in both of the regions.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Trends in rental and capital investment:</span> Between January - March 2020, the average weighted capital "asks" in Sohna Road were close to Rs 6,100 per square foot. Following the COVID-19 crisis, sales were completed at negotiated rates of up to 5–10%, which decreased regional average prices to roughly Rs 6,000 per square foot in July–September 2020. The average capital "asks" on Sohna Road have decreased by almost 14% over the four-year period between July-September 2016 and July-September 2020. <br/>On the other side, average capital rates in New Gurgaon are comparatively reasonable and are around Rs 4,700 per square foot (Jul-Sep 2020). Between July-September 2016 and July-September 2020, prices increased by almost 9%. <br/>Between July - September 2020, the average rental rates for a 2 BHK flat in Sohna Road stood at Rs 20,000 - Rs 25,000, while for a 3 BHK apartment, it was between Rs 30,000 - Rs 35,000. For about Rs 12,000 to Rs 14,000 and Rs 17,000 to Rs 20,000 per month, respectively, the same configuration is rented in New Gurgaon.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Potential Investment:</span> According to historical price trends, Sohna Road has not experienced the exponential rise in home prices that was predicted of it following a rise in commercial activity. Due to greater connectivity, the majority of MNCs chose to locate in Cyber City and close to the Huda City Centre metro station in Sector 29, which took away a lot of potential residential demand from Sohna Road. <br/> But, the upcoming 21 km Sohna Elevated Road, an important transportation infrastructure project connecting Rajiv Chowk with Sohna via Badshahpur, Bhondsi, Alipur, and Ghamroj, may be beneficial for Sohna Road in the future. The stretch connecting National Highway (NH) 48 (near Rajiv Chowk) and the Kundli-Manesar-Palwal (KMP) Expressway would offer alternate connectivity. Property prices in the number of sectors along Sohna Road will increase as a result of the opening of this elevated road in 2022. <br/> On the other hand, New Gurgaon is still in the early stages of development. The region is positioned to offer its citizens an improved quality of life with the help of improved social and civic infrastructure in the form of widely planned sewerage and drainage systems. Additionally, the area is predicted to experience rising housing demand, which will drive up prices in the mid-to-long-term. These factors include its closeness to National Highway 8 and the business complexes of Manesar and Gurgaon, including the Industrial Model Township.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Builders working in Gurgaon:</span> Some of the major and experienced builders likeShape My House, UNITECH Limited, The Shubham Group of Companies,etc in Gurgaon have completed their collaborated projects - DLF Phase -1 and DLF Phase -3. The current amount of projects is between 1-3 crores and the amount of the constructed building would be boosted up in the future which will level up the game of rental, capital, or investment returns to the ideal buyers of the properties in Gurgaon.
            </li>
          </ol>
        </motion.div>
      </div>
    </div>
  )
}

export default Blog4