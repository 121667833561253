import { X, ChevronLeft, ChevronRight } from 'lucide-react'
import React, { useEffect, useState } from 'react'

function Lightbox({ setOpenLightbox, images, currentIndex, setCurrentIndex }) {
    const currentImage = images[currentIndex];
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    const minSwipeDistance = 50;

    const onTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
      };
    
      const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
      };
    
      const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
    
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
    
        if (isLeftSwipe) {
            handleNext();
        } else if (isRightSwipe) {
            handlePrev();
        }
      };

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowRight') handleNext();
            if (e.key === 'ArrowLeft') handlePrev();
            if (e.key === 'Escape') setOpenLightbox(false);
        };

        window.addEventListener('keydown', handleKeyDown);
        
        return () => {  
            document.body.style.overflow = 'unset';
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleNext = () => {
        setCurrentIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    const handlePrev = () => {
        setCurrentIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    return (
        <div 
            className='fixed left-0 top-0 z-50 h-[100vh] bg-black/80 w-full flex items-center justify-center'
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
        >
            <button 
                onClick={() => setOpenLightbox(false)} 
                className='absolute right-3 shadow-lg top-3 hover:bg-white bg-white/70 p-2 flex justify-center items-center rounded-full font-bold'
            >
                <X size={20} />
            </button>
            <button
                onClick={handlePrev}
                className='absolute left-28 h-14 w-14 md:left-3 bottom-6 md:top-1/2 -translate-y-1/2 hover:bg-white bg-white/70 p-2 flex justify-center items-center rounded-full'
            >
                <ChevronLeft size={24} />
            </button>
            <button
                onClick={handleNext}
                className='absolute right-28 h-14 w-14 md:right-3 bottom-6 md:top-1/2 -translate-y-1/2 hover:bg-white bg-white/70 p-2 flex justify-center items-center rounded-full'
            >
                <ChevronRight size={24} />
            </button>
            <div className='max-w-[90%] max-h-[90vh]'
            >
                <img 
                    src={currentImage.src} 
                    alt="Lightbox view"
                    className='object-contain max-h-[90vh] rounded-lg'
                />
            </div>
        </div>
    );
}

export default Lightbox;
