import React from 'react'
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import blog from "./blog-asset/SMH009.jpg"

function Blog6() {
  return (
    <div className='min-h-screen w-full'>
      <Helmet>
        <title>Execution Of Real Estate India Through FY23 And Future hope</title>
        <meta
          name="description"
          content="If talk about real estate news then housing market has touched the heights in 2022 and there are some much better prospects of the real estate business in 2023"
        />
        <meta name="robots" content="noindex, nofollow"/>
      </Helmet>

      {/* Hero Banner */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[80vh] overflow-hidden"
        style={{backgroundImage:`url(${blog})`}}
      >
        <div className="absolute inset-0 bg-black/60 z-10" />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-5xl md:text-[100px] seasons mt-[100px]"
            >
              Indian Real Estate 
            </motion.h1>
            <motion.p
              initial={{ y: 40, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className='text-3xl seasons2'
            >
              Performance Of Indian Real Estate Through FY23 And Future Prospects
            </motion.p>
          </div>
        </div>
      </motion.div>

      <div className='max-w-6xl mx-auto px-4 py-16 sm:px-6 lg:px-8'>
      <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <img 
            src={blog}
            alt="Property Valuation Process" 
            className="w-full h-auto rounded-lg mb-16"
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl md:text-4xl seasons font-semibold text-[#7f5635]/90 mb-8'>Performance Of Indian Real Estate Through FY23 And Future Prospects</h2>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
          The real estate business has experienced a significant recovery despite growing building costs and a record increase in the repo rate (225 bps) in 2022. The real estate sector was finally able to take a deep breath this fiscal year after a protracted period of economic stagnation. After two protracted years of lockdowns brought on by the pandemic and the consequent economic instability, the industry has seen a thorough recovery this year across Tier I, II, and III cities.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>Some thoughts mentioned below are given by prominent leaders and experts in Real Estate</h2>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
          One of the experts in the real estate industry commented on the real estate market's performance through FY23 and its prospects - "The theme of the year 2022 has been of appreciation. According to the Indian real estate news, industry has expanded despite recent ups and downs, giving investors and homebuyers equal confidence. Since the pandemic began, there has been a sustained increase in the demand for homeownership. Homebuyers are searching for larger, greener places with value-added services and facilities that improve their well-being as a result of trends brought on by the pandemic, such as remote and hybrid working. Customers are also drawn to outlying locations that are close to urban centers as a result of this evolution in purchasing preferences. Additionally, the pandemic made real estate India appear as a more reliable option for investment, and this perception was further reinforced by the sector's year-over-year performance”.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
          "Home loan interest rates increased as a result of RBI raising repo rates. In the past, sales and consumer satisfaction were little impacted by this, though. Additionally, companies' accommodating payment arrangements have pushed purchasers to finish their purchases. Furthermore, this was the year that the new generation of environmentally concerned homebuyers began to push for creative and sustainable products. Even with the likelihood of additional rate increases, the market is likely to react favorably, as it has in the past 12 months. We expect this momentum to last over the upcoming year and are still optimistic about the industry's overall growth”, according to him.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
          Another leading expert of Real Estate says, "Figures at the end of 2022, and surprisingly, the more expensive luxury and high-end sectors are showing a marked upward swing in off-take throughout the country, despite the pandemic's tail-end, interest rate hike, job losses, a sluggish economy, and everything else taking place in the world to slow things down. A slow and cautious economy, interest rate increases, and overall increases in real estate expenses due to increased raw material prices could all make it difficult to find affordable options in the housing market. Not that it has no impact on the higher, more expensive, and more opulent reality. It's just that with higher buying power, the latter is better placed to tackle rising prices, whereas those at the bottom of the barrel think twice before loading their already creaking (and leaking) monthly budget."
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
          "The area concerned with infrastructure, logistics, and to some extent, manufacturing is the only one where things are clearly improving. The Central Government has made a significant effort to build infrastructure, including a dedicated railway freight corridor, world-class multiple-laned express highways that interconnect the nation (a far cry from the conventional 4-laned highways of yore), airports, railways, and even bus terminals that could rival (or even surpass!) the best in the west. Manufacturing and allied service organizations, including 3PL and 4PL entities, have implemented vast production, storage, and processing capacities that India had previously only heard about and seen in movies as a result of these advances".
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
          "Since Covid changed manufacturing to be China+1, India is benefiting greatly, with companies like Apple and Samsung (and with others like Oppo, Vivo, and the like) establishing sizable production facilities there. So, this is only the start. As China undergoes a second cycle of Covid, more businesses are anticipated to move their headquarters to India. In addition to their workforce needing the necessities of life, which would increase the need for reality, the thing they would need the most to start their business is a plot of land. It only means one thing towards the tail end of 2022. 2023 will bring forth some seriously good times”.
          </p>
        </motion.div>
      </div>
    </div>
  )
}

export default Blog6