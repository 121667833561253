import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';

const ServicePoint = ({ title, index }) => {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.6, delay: index * 0.1 }}
      className="flex items-start space-x-4 p-4 rounded-lg  transition-colors duration-300"
    >

      <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-full bg-[#f7eee7]">
        <span className="text-[#9f8067] font-semibold">{(index + 1).toString().padStart(2, '0')}</span>
      </div>
      <div>
        <h3 className="text-xl arvo text-gray-500">{title}</h3>
      </div>
    </motion.div>
  );
};

function Renovation() {
  const services = [
    "Masonry",
    "Carpentry",
    "Painting and Fall Ceilings",
    "General Refurbishment",
    "Tiling on both floor and wall"
  ];

  const additionalServices = [
    "Maintenance Services",
    "Commercial Tenant and Office Renovations",
    "Residential Remodeling and Repairs"
  ];

  return (
    <>
      {/* Hero Section */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[80vh] overflow-hidden"
      >
        <Helmet>
          <title>Renovation Company In Noida | Best Renovation Services</title>
          <meta
            name="description"
            content="Unlock exceptional renovation services in Noida with our dedicated renovation company in Delhi NCR."
          />
        </Helmet>
        <div className="absolute inset-0 bg-black/40 z-10" />
        <img 
          src="https://dm90w54v7z2d.cloudfront.net/servicepages/interior/3.jpg"
          alt="Renovation Services"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1 
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-4xl md:text-6xl seasons mb-4 mt-[100px]"
            >
              Innovate Home Renovation Services
            </motion.h1>
            <motion.p
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
              className="text-xl text-gray-100 seasons"
            >
              Transforming Homes in Noida, Delhi, and Gurgaon
            </motion.p>
          </div>
        </div>
      </motion.div>

      {/* Main Content */}
      <div className="max-w-5xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        {/* Introduction */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <p className="text-[#7f5635]/80 text-lg arvo leading-relaxed">
            Innovate Home Renovation has been remodeling homes in India for over a decade. When planning a home improvement project, it's crucial to choose a contractor who is dependable, trustworthy, and qualified. We provide comprehensive remodeling services for residential, bathroom, interior, and exterior projects, specializing in Noida and Delhi.
          </p>
        </motion.div>

        {/* Services */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-[#7f5635] seasons mb-3">Our Services:</h2>
          {/* <div className="w-16 h-0.5 bg-[#9f8067] mb-8" /> */}
          <div className="space-y-4">
            {services.map((service, index) => (
              <ServicePoint 
                key={index} 
                title={service}
                index={index}
              />
            ))}
          </div>
        </div>

        {/* Planning Section */}
        <motion.div 
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mb-16"
        >
          <h2 className="text-2xl font-semibold text-[#7f5635] seasons mb-6">Planning a Renovation</h2>
          <p className="text-gray-500 mb-8 arvo">
            We start with initial space planning, followed by a detailed evaluation of all requirements and customer concerns, ensuring your vision is our top priority.
          </p>

          <div className="space-y-8">
            <div>
              <h3 className="text-xl font-semibold text-[#7f5635] seasons mb-4">Interior Renovations:</h3>
              <p className="text-gray-500 arvo">
                We help you select the ideal colors, furniture, and design elements to give your rooms a complete makeover that aligns with your functional needs and aesthetic preferences.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-[#7f5635] seasons mb-4">Move In Ready Plans:</h3>
              <p className="text-gray-500 arvo">
                Before you move into your new home, we'll handle all your interior needs, from modular kitchens and wardrobe designs to wall décor, false ceilings, and flooring. We make the process simple and stress-free.
              </p>
            </div>
          </div>
        </motion.div>

        {/* Team Section */}
        <motion.div 
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mb-16"
        >
          <h2 className="text-2xl font-semibold seasons text-[#7f5635] mb-6">OUR TEAM</h2>
          <p className="text-gray-500 arvo">
            Unlike other contractors who subcontract work, we have our own team of renovation specialists. This ensures consistency, quality, and reliability in every project.
          </p>
        </motion.div>

        {/* Additional Services */}
        <div className="mb-5">
          <h2 className="text-2xl font-semibold text-[#7f5635] seasons mb-3">Additional Services:</h2>
          {/* <div className="w-16 h-0.5 bg-[#9f8067] mb-8" /> */}
          <div className="space-y-4">
            {additionalServices.map((service, index) => (
              <ServicePoint 
                key={index} 
                title={service}
                index={index}
              />
            ))}
          </div>
        </div>

        {/* Consultation Section */}
        <motion.div 
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center"
        >
          <h2 className="text-2xl font-semibold text-[#7f5635] seasons mb-6">No Cost Consultation:</h2>
          <p className="text-[#7f5635]/80 arvo mb-8">
            After concept design and material selection, we offer a free consultation. We'll present a detailed contract with a breakdown of all materials and services at a price that fits your budget. For renovation services in Noida, feel free to reach out to us.
          </p>
        </motion.div>
      </div>
    </>
  );
}

export default Renovation;