import React from 'react'
import footerimg from "../assets/footerimg.jpg"
import facebook from "../assets/facebook.png"
import footerlogo from "../assets/footer-logo.svg"

function Footer() {
    return (
        <footer className='h-full relative w-full shadow-2xl' >
            <div className='w-full h-full absolute inset-0'>
                <img src={footerimg} className='w-full h-full object-cover mix-blend-multiply object-bottom opacity-10' alt="fotterbackgroundimage" />
            </div>
            <div className='relative z-10'>
                <div className='left-0 top-0 gap-5 p-5 grid grid-cols-1 md:grid-cols-4 w-full h-full items-end'>
                    <a href='https://theshubhamgroup.com' rel="noreferrer" target='_blank'>
                        <div className='w-full flex flex-col justify-start item-start h-full'>
                            <img className='h-[200px] object-contain' src={footerlogo} alt="footerlogo" />
                        </div>
                    </a>
                    <div className='w-full h-full flex flex-col md: justify-end items-center'>
                        <div className="flex flex-col gap-3 items-center md:items-start">
                            <div className='flex gap-2'>
                                <a
                                    href={"https://www.facebook.com/ShapeMyHouse/"}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={`cursor-pointer hover:scale-110 transition-all duration-300`}
                                >
                                    <img className='h-16' src={facebook} alt="" />
                                </a>
                                <a
                                    href={`https://linkedin.com/company/the-shubham-group1`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={`cursor-pointer hover:scale-110 transition-all duration-300`}
                                >
                                    <img className='h-16 mix-blend-multiply ' src="https://brandlogos.net/wp-content/uploads/2016/06/linkedin-logo-512x512.png" alt="" />
                                </a>
                            </div>
                            <section className="flex text-center md:text-left flex-col gap-2">
                                <p className="text-black text-2xl md:text-xl font-semibold">Registered Office</p>
                                <p className="text-zinc-700 text-base font-times font-thin arvo hover:underline">
                                    The Shubham Group,  A-24, <br /> Okhla Industrial Area, Phase-2, <br /> Delhi, Pincode - 110020
                                </p>
                            </section>

                        </div>
                    </div>
                    {/* Corporate Office Section */}
                    <section className="flex text-center md:text-left flex-col gap-2">
                        <p className="text-black text-2xl md:text-xl font-semibold">Corporate Office</p>
                        <a href='https://maps.app.goo.gl/dNzTgKr58UaUHy767' target='_blank' rel='noreferrer'>
                            <p className="text-zinc-700 text-base font-times font-thin arvo hover:underline">
                                B - 315, <br /> Sector-105 Noida, <br /> Uttar Pradesh, Pincode - 201304
                            </p>
                        </a>
                    </section>
                    <div className='flex text-center md:text-left flex-col gap-2'>
                        {/* Contact Us */}
                        <p className='text-black text-2xl md:text-xl font-semibold mb-[10px]'>Contact Us</p>
                        <p className='text-zinc-700 text-base font-times font-thin arvo '>
                            <a className='hover:underline' href="tel:+91-9870168989">9870 168 989
                            </a><br />
                            <a className='hover:underline' href="mailto:info@shubhamgroup.com">info@shubhamgroup.com</a>
                        </p>
                    </div>
                </div>
                <div className='w-full h-full flex justify-center items-center'>
                    <p className='text-gray-800 text-xs text-center my-3 md:text-base font-times font-thin arvo'>
                        Copyright © {new Date().getFullYear()} Shape My House. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    )
}
export default Footer;
