import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';
import ServicesGrid from '../home/Services';
import Collaboration from './Collaboration';


// Feature section component
const FeatureSection = ({ feature, index }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.6 }}
      className="mb-16"
    >
      <div className={`flex flex-col gap-8 md:gap-12 items-center `}>
        {/* Image Section */}
        <motion.div
          initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
          animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
          transition={{ duration: 0.8 }}
          className="relative w-full md:h-[300px] h-[400px] rounded-2xl overflow-hidden"
        >
          <img
            src={feature.img}
            alt={feature.title}
            className="w-full h-full object-cover object-center"
          />
          <div className="absolute inset-0 bg-black/20 hover:bg-black/10 transition-colors duration-300" />
        </motion.div>

        {/* Content Section */}
        <div className="space-y-8">
          <h2 className="text-2xl font-semibold seasons text-[#7f5635]">{feature.title}</h2>
          <div className="space-y-8">
            {feature.items.map((item, idx) => (
              <motion.div
                key={idx}
                initial={{ opacity: 0, y: 20 }}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                transition={{ duration: 0.6, delay: idx * 0.2 }}
                className="space-y-3"
              >
                <ul className=' ml-5 list-disc'>
                  <li className="text-xl font-medium arvo text-[#7f5635]/80">{item.subtitle}</li>
                </ul>
                <p className="text-gray-500 leading-relaxed arvo">{item.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

function Construction() {
  const features = [
    {
      img: "https://dm90w54v7z2d.cloudfront.net/servicepages/construction/c1.jpg",
      title: "Exceptional Quality and Expertise",
      items: [
        {
          subtitle: "Experienced Workforce",
          description: ""
        },
        {
          subtitle: "Systematic Approach",
          description: ""
        }
      ]
    },
    {
      img: "https://dm90w54v7z2d.cloudfront.net/servicepages/construction/c2.jpg",
      title: "Comprehensive Project Management",
      items: [
        {
          subtitle: "Quality Oversight",
          description: ""
        },
        {
          subtitle: "Detailed Reporting",
          description: ""
        }
      ]
    },
    {
      img: "https://dm90w54v7z2d.cloudfront.net/servicepages/construction/c3.jpg",
      title: "Durable and Efficient Structures",
      items: [
        {
          subtitle: "Best Practices",
          description: ""
        },
        {
          subtitle: "Customized Solutions",
          description: ""
        }
      ]
    }
  ];

  return (
    <div className='overflow-hidden'>
      <Helmet>
        <title>Construction Services in Noida- Top Colonizers in Noida</title>
        <meta
          name="description"
          content="Looking for the best construction company in Noida? Shape My House, your premier builder and colonizer in Noida brings expertise to every construction project."
        />
      </Helmet>
      {/* Hero Section with Image */}

      {/* <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[80vh] overflow-hidden"
      >
        
        <div className="absolute inset-0 bg-black/40 z-10" />
        <img
          src="https://dm90w54v7z2d.cloudfront.net/servicepages/construction/1.jpg" 
          alt="Construction Services"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-4xl md:text-6xl seasons mt-[120px]"
            >
              Construction <span className='arvo'>&</span> Collaboration
              <br />
              <span
                className="text-xl arvo md:text-2xl"
              >
                Building Dreams Into Reality
              </span>
            </motion.h1>
          </div>
        </div>
      </motion.div> */}

      {/* Main Content */}
      <div className="max-w-5xl mx-auto lg:px-8">
        <div className='container mx-auto mt-5 md:my-[15px] relative z-20'>
          <div className='flex flex-col items-center'>
            <p className='text-4xl text-center px-8 mb-3 md:text-6xl font-medium seasons text-[#7f5635]'>
              Contruction
            </p>
          </div>
        </div>
        {/* <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <p className="arvo max-w-2xl mx-auto text-lg text-[#7f5635]/80">
            At Shape My House, we pride ourselves on delivering top-notch civil contracting services that consistently exceed our clients expectations. Here's what sets us apart:
          </p>
        </motion.div> */}

        {/* Feature Sections */}
        <div className='grid grid-cols-1 md:grid-cols-3 gap-8 p-5  md:gap-12'>
          {features.map((feature, index) => (
            <FeatureSection key={index} feature={feature} index={index} />
          ))}
        </div>

        <Collaboration />

        {/* Service Grid */}
        {/* <ServicesGrid /> */}

        {/* Final CTA Section */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center p-5 relative"
        >
          <h2 className="text-2xl font-semibold seasons text-[#7f5635] mb-2 md:mb-6">Your Dream Home, Built to Last</h2>
          <div className="space-y-6 max-w-3xl mx-auto">
            <p className="text-[#7f5635]/80 text-lg arvo ">
              You can rely on Shape My House, a construction company in Noida, to create your ideal home with the highest attention to detail and accuracy. Being Delhi's top civil engineering firm, we promise a house that not only lives up to your expectations but exceeds them.
            </p>
            <p className="text-[#7f5635]/80 text-lg arvo">
              Discover the Shape My House distinction in Construction services in Noida where client happiness, dependability, and quality are the cornerstones of everything we do. Reach out to us right now to begin creating your ideal house!
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Construction;