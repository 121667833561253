import React from 'react'
import { motion } from 'framer-motion'
import ServicesGrid from '../components/home/Services'
import { Helmet } from 'react-helmet'
import Construction from '../components/services/Construction'



function About() {
  return (
    <>
      {/* Hero Section with Image */}
      <Helmet>
        <title>Construction Company in Delhi NCR - Builder in Noida</title>
        <meta
          name="description"
          content="Shape My House is your trusted construction company in Delhi NCR and a renowned real estate developer in Noida. Explore our portfolio of innovative projects now"
        />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[400px] overflow-hidden"
      >
        <div className="absolute inset-0 bg-black/40 z-10" /> {/* Dark overlay */}
        <img
          src="https://dm90w54v7z2d.cloudfront.net/about.jpg" // Make sure to add your image to the public/images folder
          alt="Construction Services"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-5xl md:text-8xl seasons mb-6 mt-[100px]"
            >
              Shape My House
            </motion.h1>
            <motion.p
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
              className="text-xl arvo md:text-2xl"
            >
              Building Dreams Into Reality
            </motion.p>
          </div>
        </div>
      </motion.div>
      <div className='container mx-auto relative mt-12 z-20'>
        <div className='flex flex-col items-center'>
          <p className='text-3xl text-center px-8 md:text-6xl font-medium seasons text-[#7f5635]'>
            Welcome to Shape My House
            <p className='text-xl md:text-3xl mt-5'>Your One–Stop Solution for All Construction Needs</p>
          </p>
          <p className='text-lg md:text-xl text-[#9f8067]/90 text-center my-8 arvo md:w-[90%] px-5'>
          At Shape My House, we bring your dream home to life with expert solutions in construction, collaboration, and interior design. if you're looking to build from the ground up , you can get all the services under on roof at shape my house.
          </p>
          <Construction/>
          <ServicesGrid />
        </div>
      </div>
    </>
  )
}

export default About