import React, { useState } from 'react'
import { motion } from 'framer-motion'
import dlf1 from "./images/dlf/1.jpg"
import dlf2 from "./images/dlf/2.jpg"
import dlf3 from "./images/dlf/3.jpeg"
import dlf4 from "./images/dlf/4.jpeg"
import Lightbox from '../Lightbox'
import { BedDouble, Car, Utensils, Wine, SquareParking, Airplay, CookingPot, School, StepForward } from 'lucide-react'
import { Helmet } from 'react-helmet'


function Project2() {

  const [openLightbox, setOpenLightbox] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const projectImages = [
    {
      src: dlf1,
      alt: "DLF Phase 3 Exterior",
      title: "Luxury Apartment Exterior"
    },
    {
      src: dlf2,
      alt: "Living Space",
      title: "Elegant Living Area"
    },
    {
      src: dlf3,
      alt: "Living Space",
      title: "Elegant Living Area"
    },
    {
      src: dlf4,
      alt: "Living Space",
      title: "Elegant Living Area"
    },
  ];

  function lightboxopen(index) {
    setCurrentImageIndex(index)
    setOpenLightbox(true)
  }

  return (
    <div className='min-h-screen w-full'>

      <Helmet>
        <title>
          Best 4 BHK Apartment In DLF Phase 3 Gurgaon | ShapeMyHouse
        </title>
        <meta name='description' content=' Explore Luxurious 4 Bhk Flats in Dlf Phase 3, Gurgaon, Offering Spacious Living and Modern Amenities. Find Your Dream 4 Bhk in Gurgaon Here.'/>
      </Helmet>

      {/* Hero Banner */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[80vh] overflow-hidden"
      >
        <div className="absolute inset-0 bg-black/40 z-10" />
        <img
          src={dlf1}
          alt="Luxury Property"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-5xl md:text-[100px] seasons mt-[100px]"
            >
              DLF Phase 3
            </motion.h1>
            <motion.p
              initial={{ y: 40, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className='text-3xl seasons2'> Luxurious 4 BHK Apartment
            </motion.p>
          </div>
        </div>
      </motion.div>
      {openLightbox && (
        <Lightbox
          images={projectImages}
          currentIndex={currentImageIndex}
          setCurrentIndex={setCurrentImageIndex}  // Fix: Pass setCurrentIndex
          setOpenLightbox={setOpenLightbox}
        />
      )}

      <div className='max-w-6xl mx-auto px-4 py-16 sm:px-6 lg:px-8'>
        {/* Project Overview section */}
        <motion.div className="mb-8">
          <h2 className='text-3xl md:text-4xl seasons font-semibold text-[#7f5635]/90 mb-8'>Project Overview</h2>
        </motion.div>

        {/* Project Description */}
        <motion.div className="mb-16">
          <p className="text-gray-500 leading-relaxed arvo mb-8 text-justify">
            Looking for spacious and luxurious flats for sale in Gurgaon? Look no further! At Shape My House, we provide exquisite apartments in the sought-after DLF Phase 3 area of Gurgaon, offering the epitome of contemporary living. Gurgaon, recognized for its fast urbanization and robust infrastructure, has emerged as a prime destination for real estate investments.
          </p>

          {/* Enhanced Property Specifications */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="rounded-xl mb-2 overflow-hidden"
          >
            <div className="border-b border-[#7f5635]/10">
              <h3 className="text-2xl seasons text-[#7f5635] px-8 py-6 font-bold">Property Specifications</h3>
            </div>

            <div className="p-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                <div className="flex items-center space-x-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-[#7f5635]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                  </svg>
                  <div>
                    <p className="text-sm text-gray-500 arvo">Total Area</p>
                    <p className="text-lg font-semibold text-[#7f5635]">406 sq. Mt. (485 sq. ft.)</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <Car size={40} className='text-[#7f5635]' />
                  <div>
                    <p className="text-sm text-gray-500 arvo">Road</p>
                    <p className="text-lg font-semibold text-[#7f5635]">15 Mt. Wide</p>
                  </div>
                </div>
              </div>

              <div className="rounded-lg">
                <h4 className="text-xl seasons text-[#7f5635] mb-4 font-bold">Floor Plan Facilities</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {[
                    { icon: <BedDouble size={20} className='text-[#7f5635]' />, text: "4 Bedrooms with Attached Toilets" },
                    { icon: <Airplay size={20} className='text-[#7f5635]' />, text: "Drawing room" },
                    { icon: <Utensils size={20} className='text-[#7f5635]' />, text: "Dining area" },
                    { icon: <CookingPot size={20} className='text-[#7f5635]' />, text: "Kitchen" },
                    { icon: <Wine size={20} className='text-[#7f5635]' />, text: "Bar" },
                    { icon: <School size={20} className='text-[#7f5635]' />, text: "Puja Room" },
                    { icon: <SquareParking size={20} className='text-[#7f5635]' />, text: "2 Car Parking Spaces Undercover" },
                    { icon: <StepForward size={20} className='text-[#7f5635] -rotate-90' />, text: "5 Pax Elevator" },
                  ].map((facility, index) => (
                    <div key={index} className="flex items-center gap-4 space-x-2">
                      {facility.icon}
                      <span className="arvo text-gray-600">{facility.text}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>

          <p className="text-gray-500 arvo leading-relaxed mb-6 text-justify">
            If you are looking for a home that combines sophistication, comfort, and convenience, a flat in Gurgaon is an exceptional choice. These spacious apartments provide ample room for your family's needs, while ensuring a high standard of living. DLF Phase 3 is a prestigious locality known for its upscale residential offerings. It's the perfect place for those seeking tranquility amidst the bustling city life.
          </p>
          <p className="text-gray-500 arvo leading-relaxed text-justify">
            The area boasts excellent connectivity, proximity to commercial hubs, and top-notch amenities, making it an ideal choice for families and professionals alike. Our services feature a curated selection of flats for sale in Gurgaon, with a particular focus on DLF Phase 3. These apartments are thoughtfully designed to cater to your modern lifestyle needs. From spacious bedrooms and elegant living spaces to state-of-the-art kitchens, every detail is crafted to perfection.
          </p>
        </motion.div>

        {/* Why Choose Shape My House section */}
        <motion.div className="mb-16">
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>Why Choose Shape My House?</h2>
          <p className="text-gray-500 arvo leading-relaxed mb-6 text-justify">
            Shape My House provides a user-friendly platform to simplify your search. Our extensive database of properties allows for easy filtering and finding of the ideal flat that meets both your preferences and budget. Our team of experienced real estate experts is dedicated to guiding you through every step of the buying process.
          </p>
          <p className="text-gray-500 arvo leading-relaxed text-justify">
            Whether you're a first-time homebuyer or a seasoned investor, we provide personalized guidance to ensure a seamless transaction.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>Project Gallery</h2>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
            {projectImages.map((image, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="relative group overflow-hidden rounded-lg cursor-pointer"
                onClick={() => lightboxopen(index)}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="w-full h-[300px] object-cover transition-transform duration-300 group-hover:scale-105"
                />
                {/* <div className="absolute inset-0 bg-black/60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                  <h3 className="text-white text-xl seasons">{image.title}</h3>
                </div> */}
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Project2