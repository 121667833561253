import React from 'react'
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import blog from "./blog-asset/SMH007.jpg"

function Blog3() {
  return (
    <div className='min-h-screen w-full'>
      <Helmet>
        <title>Comfortable Home Renovation Options | Home Renovation Ideas</title>
        <meta
          name="description"
          content="If you are planning for old house renovation then Shape My House will be the best firm to fulfil your dreams as it is serving the best home renovations services"
        />
        <meta name="robots" content="noindex, nofollow"/>
      </Helmet>

      {/* Hero Banner */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[80vh] overflow-hidden"
        style={{backgroundImage:`url(${blog})`}}
      >
        <div className="absolute inset-0 bg-black/60 z-10" />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-5xl md:text-[100px] seasons mt-[100px]"
            >
              Home Renovation 
            </motion.h1>
            <motion.p
              initial={{ y: 40, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className='text-3xl seasons2'
            >
              Simple Options for Home Renovation in India
            </motion.p>
          </div>
        </div>
      </motion.div>

      <div className='max-w-6xl mx-auto px-4 py-16 sm:px-6 lg:px-8'>
      <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <img 
            src={blog}
            alt="Property Valuation Process" 
            className="w-full h-auto rounded-lg mb-16"
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>Why Consider Home Renovation?</h2>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
          Your charming old house where you have lived for ages, and dream of spending many more memorable years in, is starting to show its age. When it comes to maintenance, older houses and flats can provide their own challenges. And occasionally you discover that your luxuries need to be more accommodating to your expanding family's needs. Here are some things to consider if you're thinking to renovate your home.
          </p>
          <p className="text-gray-500 leading-relaxed arvo mb-6 text-justify">
          Your home renovation ideas may be motivated by a variety of factors. You could want to sell your house and want to boost its market worth and commercial value. Or perhaps you want to give your house a more contemporary makeover. Another justification for house renovations can be security and safety-related issues. After all, an old house would have experienced a lot of damage from the weather, and if you don't maintain it to keep up with modern trends and materials, it could even be a security issue!
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <h2 className='text-3xl seasons font-semibold text-[#7f5635]/90 mb-8'>Steps for Home Renovation</h2>
          <ol className="list-decimal list-inside text-gray-500 leading-relaxed arvo mb-6 text-justify pl-4">
            <li className="mb-4">
              <span className="font-semibold">Assess:</span> Assessing your home's structural components is the first step in any restoration project. Make sure your home's structural components are solid, meaning they can sustain use for many more years. This includes the walls, beams, floors, sidings, windows, electrical system, plumbing, water supply, foundation, and roof (if the structure is an independent house). You might discover that you need to completely rebuild or replace some components after tearing them down. This initial step will significantly define your renovation budget for the home.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Choose Your Goal:</span> Your priorities may alter depending on why you want to renovate your home. For instance, you don't need to worry too much about interior design if the goal is to eventually sell the house. You might want to put your attention on fixing any harm done to the original building. On the other hand, you must pay close attention to even the smallest aspects if you want to update the look and feel because you intend to stay.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Plan:</span> To prevent subsequent costly work, it's essential to think about the minute details when renovating a property. One part of a restoration project is financial preparation, but it's not the only part. It takes time to develop an idea from a doodle on a paper napkin into a fully realized architectural and interior design plan and implementation. Decide first and foremost on the scope of the reconstruction, that is, whether you want a total overhaul or just a quick facelift. Determine what you would like to do personally versus what you would like to outsource. Increase the financial budget by taking into account labor and service fees, material costs, and overheads of unexpected expenses. Prioritizing planning requires diving deeper than surface-level details.interactive displays.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Interiors:</span> Interior design projects can be done by DIY or hire an interior pro. To get a homely feeling it's a good idea to add a personal touch to the decor. As per saying, a house typically reflects the personalities of the people who live in it, so personalizing the little things to reflect your preferences can transform it from a cold, uninviting house into a cozy, welcoming home. Examine the lighting (fluorescent versus yellow, visible versus hidden, useful versus stylized, etc.), colors (paint or wallpaper), and furnishings (traditional vs eclectic vs. contemporary, etc). These are the basic kick-starters to help you give shape to your dream home.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Get Professional Assistance:</span> Unless you are an expert in architecture and interior design, it is unlikely that you can handle it alone. So if you made up your mind about the house renovation you can hire professionals, who will let you know the exact requirement of the work for transforming your basic home structure into your dream home. Shape my house is the most popular company in Delhi/NCR which provides renovation services along with collaboration, construction, and interior designing and has a skilled team to ensure a high-quality project that follows current trends and endures. They have successfully delivered 2 projects in DLF Phase - 1 and 3, Shape My House, have their own persona of work, showing utter transparency with the clients. They’ll sketch out the vision of your dream home and complete the project in the given time frame within your price range.
            </li>
          </ol>
        </motion.div>
      </div>
    </div>
  )
}

export default Blog3