import React from 'react'
import { motion } from 'framer-motion';
// import { useInView } from 'react-intersection-observer';
import Project from "../components/home/Projects"
import { Helmet } from 'react-helmet';


function Projects() {

  // const [ref, inView] = useInView({
  //   threshold: 0.3,
  //   triggerOnce: true
  // });

  return (
    <>
      <Helmet>
        <title>Ongoing Projects in Gurgaon, Delhi and Noida- Shape My House</title>
        <meta
          name="description"
          content="Welcome to Shape My House if you're searching for the top building firm in Delhi, Noida, Gurgaon and Greater Noida- a trustworthy civil construction company."
        />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[50vh] overflow-hidden"
      >
        <div className="absolute inset-0 bg-black/60 z-10" />
        <img
          src="https://dm90w54v7z2d.cloudfront.net/projects.jpg"
          alt="Collaboration Services"
          className="w-full h-full object-cover object-top"
        />
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-5xl md:text-6xl seasons mt-[100px] mb-6"
            >
              Our Projects
            </motion.h1>
          </div>
        </div>
      </motion.div>
      <div className='max-w-7xl conatainer mx-auto mt-8 '>
        <Project />
      </div>

    </>
  )
}

export default Projects