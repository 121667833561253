import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';


const ServicePoint = ({ title, description, index, subPoints = [] }) => {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.6, delay: index * 0.1 }}
      className="flex items-start space-x-4 p-4 rounded-lg transition-colors duration-300"
    >
      
      <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-full bg-[#f7eee7]">
        <span className="text-[#9f8067] font-semibold">{(index + 1).toString().padStart(2, '0')}</span>
      </div>
      <div className="space-y-4">
        <div>
          <h3 className="text-xl text-[#7f5635]/80 arvo mb-2">{title}</h3>
          {description && <p className="text-gray-500 arvo">{description}</p>}
        </div>
        {subPoints.length > 0 && (
          <div className="space-y-3 pl-4">
            {subPoints.map((point, idx) => (
              <div key={idx} className="space-y-1">
                <h4 className="font-medium text-[#7f5640] arvo">{point.title}</h4>
                <p className="text-gray-500 arvo">{point.description}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </motion.div>
  );
};

function ArchitectureDesign() {
  const architecturalServices = [
    {
      title: "Customized Consultation for Design:",
      subPoints: [
        {
          title: "Recognizing Your Vision:",
          description: "We start with a thorough consultation to ascertain your particular requirements, inclinations, and way of life."
        },
        {
          title: "Concept Development:",
          description: "Our architects work with you to translate your ideas into a workable concept that satisfies both your functional needs and aesthetic preferences."
        }
      ]
    },
    {
      title: "Extensive Scheduling and Evaluation:",
      subPoints: [
        {
          title: "Site investigation:",
          description: "In order to comprehend the site's possibilities and constraints, we perform a comprehensive investigation of it."
        },
        {
          title: "Space Optimization:",
          description: "To ensure optimal efficiency and usefulness, our designs place a strong emphasis on space optimization."
        }
      ]
    },
    {
      title: "Creative Approaches to Design:",
      subPoints: [
        {
          title: "Sustainable Design:",
          description: "By incorporating sustainable techniques into our designs, we encourage environmental responsibility and energy efficiency."
        },
        {
          title: "Modern Aesthetics:",
          description: "We keep classic elegance while reflecting current trends in our designs."
        }
      ]
    },
    {
      title: "Detailed Sketches of the Architecture:",
      subPoints: [
        {
          title: "Accuracy and Clarity:",
          description: "We guarantee accuracy and precision by offering comprehensive architectural drawings that include sections, elevations, and floor plans."
        },
        {
          title: "3D Visualizations:",
          description: "Our 3D renderings provide you with a realistic perspective of your project and assist you in seeing it through to completion."
        }
      ]
    },
    {
      title: "Approvals and Regulatory Compliance:",
      subPoints: [
        {
          title: "Navigating Regulations:",
          description: "We take care of the required approvals and guarantee adherence to regional building norms and standards."
        },
        {
          title: "Easy Process:",
          description: "Our staff handles every step of the approval procedure, giving you a hassle-free experience."
        }
      ]
    },
    {
      title: "Management of Projects:",
      subPoints: [
        {
          title: "Smooth Coordination:",
          description: "To guarantee a project's smooth execution, we communicate with engineers, contractors, and other stakeholders."
        },
        {
          title: "On-time Delivery:",
          description: "Your project will be finished on schedule and under budget thanks to our project management."
        }
      ]
    }
  ];

  const specializations = [
    {
      title: "Residential design",
      description: "is the process of making stunning, useful homes that express your individuality and way of life."
    },
    {
      title: "Commercial design",
      description: "is the process of creating effective and motivating environments for companies, workplaces, and retail stores."
    },
    {
      title: "Remodeling and renovation",
      description: "converting old spaces into contemporary, useful, and visually beautiful settings."
    },
    {
      title: "Landscape architecture",
      description: "Adding tasteful and imaginative landscape designs to outdoor areas."
    }
  ];

  return (
    <>
      {/* Hero Section */}
      
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative w-full h-[80vh] overflow-hidden"
      >
        <div className="absolute inset-0 bg-black/40 z-10" />
        <img 
          src="https://dm90w54v7z2d.cloudfront.net/servicepages/arc/5.jpg"
          alt="Architectural Design Services"
          className="w-full h-full object-cover"
        />
        <Helmet>
          <title>Architecture Company In Noida | Architectural Designs</title>
          <meta
            name="description"
            content="Get exceptional architecture services in Noida by our renowned architecture firm in Delhi NCR, serving with the best architectural designs."
          />
        </Helmet>
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <div className="text-center text-white max-w-4xl mx-auto px-4">
            <motion.h1 
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-4xl md:text-6xl seasons mb-4 mt-[100px]"
            >
              Architectural Design Services <br/> <span className='text-3xl'>Delhi NCR</span>
            </motion.h1>
          </div>
        </div>
      </motion.div>

      {/* Main Content */}
      <div className="max-w-5xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        {/* Introduction */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <p className="text-[#7f5635]/80 arvo text-lg leading-relaxed">
            Here at Shape My House, we take your architectural fantasies and make them come true. Our specialty is coming up with creative and useful architecture solutions that seamlessly combine style and functionality. We are dedicated to providing designs that improve your quality of life and place of employment, and we serve the whole Delhi-NCR area, including Gurgaon, Noida, and beyond.
          </p>
        </motion.div>

        {/* Architectural Services */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-[#7f5635]/80 seasons mb-3">Our Services for Architectural Design:</h2>
          {/* <div className="w-16 h-0.5 bg-[#9f8067] mb-8" /> */}
          <div className="space-y-4">
            {architecturalServices.map((service, index) => (
              <ServicePoint 
                key={index}
                title={service.title}
                subPoints={service.subPoints}
                index={index}
              />
            ))}
          </div>
        </div>

        {/* Why Choose Us */}
        <motion.div 
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mb-16"
        >
          <h2 className="text-2xl font-semibold text-[#7f5635]/80 seasons mb-6 arvo">Why Should You Choose us For Architectural Style?</h2>
          <p className="text-gray-500 arvo">
            Shape My House, an architect company in Noida offers a team of talented architects who bring years of expertise and a passion for creative design to every project. We prioritize your needs and vision, ensuring that the final design exceeds your expectations. Upholding the highest standards of quality in all aspects of our work, we provide comprehensive architectural design services from the initial consultation to the project's conclusion.
          </p>
        </motion.div>

        {/* Specializations */}
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-[#7f5635]/80 seasons mb-3">Our Specializations:</h2>
          {/* <div className="w-16 h-0.5 bg-[#9f8067] mb-8" /> */}
          <div className="space-y-4">
            {specializations.map((spec, index) => (
              <ServicePoint 
                key={index}
                title={spec.title}
                description={spec.description}
                index={index}
              />
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <motion.div 
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center rounded-xl"
        >
          <h2 className="text-2xl font-semibold text-[#7f5635]/80 seasons mb-6">Start Using Shape My House</h2>
          <p className="text-[#7f5635]/80 mb-8 arvo">
            Are you prepared to realize your architectural dream? For a free consultation and assistance in designing a home that is exclusively yours, get in touch with Shape My House right now.
          </p>
        </motion.div>
      </div>
    </>
  );
}

export default ArchitectureDesign;